const storage = window.sessionStorage;
export const clear = () => storage.clear();
export const get = (key) => storage.getItem(key);
export const hasKeys = (keys) => (keys.map(key => get(key))
    .reduce((prior, current) => prior && Boolean(current), true));
export const put = (key, value) => storage.setItem(key, value);
export const putAll = (map) => {
    Object.keys(map).forEach(key => put(key, map[key]));
};
export const remove = (key) => storage.removeItem(key);
export const removeAll = (keys) => keys.forEach(remove);
