import React from 'react';
import { injectIntl } from 'react-intl';
import { css, cx } from 'emotion';
import { messages } from '../../intl';
import { sizing, typographyStyles } from '../../util/Styles';

const customerDetailStyle = css`
    margin-left: ${sizing.medium};
    strong {
        margin-left: ${sizing.xsmall};
    }
`;

const customerStatusStyle = css`
    padding-left: ${sizing.medium};
    border-left: double black;
`;

export const InternalCustomerHeader = ({
    customerName,
    customerAccountCreationDate,
    customerIDVerificationStatus,
    isKYCEnabledMarketplace,
    customerARIAccountStatus,
    intl,
}) => (
    <div>
        <span className={typographyStyles.fontSize4}>{customerName}</span>
        <span className={customerDetailStyle}>
            {intl.formatMessage(messages.customerSince)}
            <strong>
                {intl.formatDate(customerAccountCreationDate, {
                    month: 'short',
                    day: 'numeric',
                    year: 'numeric',
                    timeZone: 'UTC',
                })}
            </strong>
        </span>
        {isKYCEnabledMarketplace
             && <span className={cx(customerDetailStyle, customerStatusStyle)}>
                 {intl.formatMessage(messages.idVerificationStatus)}
                 <strong>
                     {intl.formatMessage(messages[String(customerIDVerificationStatus)])}
                 </strong>
             </span>
        }
        <span className={cx(customerDetailStyle, customerStatusStyle)}>
            {intl.formatMessage(messages.ariAccountStatus)}
            <strong>
                {intl.formatMessage(messages[String(customerARIAccountStatus)])}
            </strong>
        </span>
    </div>
);

export default injectIntl(InternalCustomerHeader);
