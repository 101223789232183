import { fetchSDK } from './cloudWatchSDKManager';

const getCurrentUTCDate = () => new Date()
    .toISOString()
    .split('T')[0];

const buildLogStreamName = (contactIdentifier, uniqueSessionId) => (
    `/${getCurrentUTCDate()}/${contactIdentifier}/${uniqueSessionId}`
);

export class CloudWatchIngester {
    constructor(contactIdentifier, uniqueSessionId, logGroupName) {
        this.ingesting = false;
        this.logStreamName = buildLogStreamName(contactIdentifier, uniqueSessionId);
        this.nextSequenceToken = null;
        this.logGroupName = logGroupName;
    }

    async initialize() {
        if (!this.logStreamCreated) {
            const sdk = await fetchSDK();
            await sdk.createLogStream({
                logGroupName: this.logGroupName,
                logStreamName: this.logStreamName,
            }).promise();
            this.logStreamCreated = true;
        }
    }

    async ingest(logEvents) {
        if (logEvents.length === 0) return;
        if (this.ingesting) throw new Error('Already ingesting');

        this.ingesting = true;
        try {
            await this.initialize();
            const sdk = await fetchSDK();
            const { nextSequenceToken } = await sdk.putLogEvents({
                logEvents,
                logGroupName: this.logGroupName,
                logStreamName: this.logStreamName,
                sequenceToken: this.nextSequenceToken,
            }).promise();
            this.nextSequenceToken = nextSequenceToken;
        } finally {
            this.ingesting = false;
        }
    }
}
