import * as sdk from '@aws-crypto/client-browser';
import { fromBase64, toBase64 } from '@aws-sdk/util-base64-browser';
import config from '../../config';
import * as encoder from './TextEncoding';
import { ensureCredentials } from '../Session';

// KMS - AWS Key Management Service

/* Encryption algorithm used need not strictly match with the server side, since
the envelope will contain all the details to decrypt a message. But we intend to
have them in sync with the one used in the server side. */
const ENCRYPTION_ALGORITHM = 'aes_256_gcm_iv12_tag16_hkdf_sha256';

/* Encryption context is a dictionary that can optionally be provided while
encrypting. If provided during encryption, the same dictionary should also be
provided during decryption. We have no plans to use it in the near future. So,
it is an empty map. */
const ENCRYPTION_CONTEXT = {};

let materialsManager;

/**
 * Initialize the crypto module using the default KMS configuration.
 * @return {Object} The kms material manager.
 */
async function initializeCrypto() {
    if (materialsManager !== undefined) {
        return;
    }

    materialsManager = new sdk.KmsKeyringBrowser({
        clientProvider: sdk.getClient(sdk.KMS, {
            credentials: await ensureCredentials(),
            region: config.AWS_REGION,
        }),
        generatorKeyId: config.KMS_KEY_ARN,
    });
}

/**
 * Encrypts the given String. The same string might get encrypted to different
 * cipherTexts, so this method does not guarantee the same output for the same
 * input.
 *
 * @param {string} plainText - String to be encrypted.
 * @return {Promise<any>} A promise that will resolve to the base64 encoded
 *          cipher text of the given plainText. The promise will be rejected if
 *          there were any errors during encryption (most likely due to trouble
 *          talking with AWS KMS).
 */
export function encrypt(plainText) {
    return initializeCrypto().then(() => new Promise((resolve, reject) => {
        const plainTextBytes = encoder.encode(plainText);
        sdk.encrypt(materialsManager, plainTextBytes, {
            algorithm: ENCRYPTION_ALGORITHM,
            encryptionContext: ENCRYPTION_CONTEXT,
        }).then((encryptionResponse) => {
            resolve(toBase64(encryptionResponse.result));
        }, reject);
    }));
}

/**
 * Decrypts the given base64 encoded cipherText. The cipherText should have been
 * obtained using KMS's envelope encryption. This method returns the same plain
 * text for the same base64 cipher text.
 *
 * @param {string} base64CipherText - Base64 encoded cipher text.
 * @return {Promise<any>} A promise that will resolve to the plain text
 *          corresponding to the given cipher text. The promise will be rejected
 *          if there were any errors during decryption (most likely due to
 *          trouble talking with AWS KMS).
 */
export function decrypt(base64CipherText) {
    return initializeCrypto().then(() => new Promise((resolve, reject) => {
        const cipherMessageBytes = fromBase64(base64CipherText);
        sdk.decrypt(materialsManager, cipherMessageBytes)
            .then((decryptionResponse) => {
                resolve(encoder.decode(decryptionResponse.plaintext));
            }, reject);
    }));
}
