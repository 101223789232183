export default {
    API_HOSTNAME: 'devo.eu.cap.cs.a2z.com',
    AUTHENTICATED_REDIRECT_URL: 'https://www.devo.eu.cap.cs.a2z.com/',
    AWS_REGION: 'us-west-2',
    FEDERATE_CLIENT_ID: 'CAPWebsite-Integ-EU',
    FEDERATE_AUTHORIZATION_ENDPOINT: 'https://idp-integ.federate.amazon.com/api/oauth2/v1/authorize',
    GLOBAL_IDENTITY_CLIENT_ID: 'CAPWebsite-devo-na',
    GLOBAL_IDENTITY_LOGIN_URL: 'https://amzheimdall-na.integ.amazon.com/login',
    KMS_KEY_ARN: 'arn:aws:kms:us-west-2:799516799509:key/89dbb3f9-194d-4a02-a54a-c5ae39bfda49',
    REALM: 'eu',
};
