import React, { Component, Fragment } from 'react';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import { injectGlobal } from 'emotion';
import { injectIntl } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import { colors } from '../../util/Styles';
import createClient from '../../util/Api/createClient';
import { centeredSpinnerStyle } from './styles';
import Hello from '../Hello';
import Header from '../Header';

injectGlobal(`
    html {
        background-color: ${colors.grey150};
    }
`);

const renderError = intl => (
    <Alert
        header={intl.formatMessage({
            id: 'errorMessage',
            defaultMessage: 'Something went wrong',
        })}
        type="error"
    />
);

const renderSpinner = () => (
    <div className={centeredSpinnerStyle}>
        <Spinner size="large" />
    </div>
);

export class InternalApp extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const { ensureCredentials } = this.props;
        return ensureCredentials() // returning this promise for tests
            .then(credentials => this.setState({ credentials }))
            .catch(error => this.setState({ error }));
    }

    getAppBody() {
        const { credentials, error } = this.state;
        const { inputs, intl, sessionId } = this.props;

        if (error) {
            return renderError(intl);
        }
        if (credentials) {
            return (
                <ApolloProvider client={createClient(credentials)}>
                    <div>
                        <Hello inputs={inputs} sessionId={sessionId} />
                    </div>
                </ApolloProvider>
            );
        }
        return renderSpinner();
    }

    render() {
        return (
            <Fragment>
                <Header version={0} />
                { this.getAppBody() }
            </Fragment>
        );
    }
}

export default injectIntl(InternalApp);
