export default {
    API_HOSTNAME: 'test.na.cap.cs.a2z.com',
    AUTHENTICATED_REDIRECT_URL: 'https://www.test.na.cap.cs.a2z.com/',
    AWS_REGION: 'us-west-2',
    FEDERATE_CLIENT_ID: 'CAPWebsite-Integ-NA',
    FEDERATE_AUTHORIZATION_ENDPOINT: 'https://idp-integ.federate.amazon.com/api/oauth2/v1/authorize',
    GLOBAL_IDENTITY_CLIENT_ID: 'CAPWebsite-devo-na',
    GLOBAL_IDENTITY_LOGIN_URL: 'https://amzheimdall-na.integ.amazon.com/login',
    KMS_KEY_ARN: 'arn:aws:kms:us-west-2:900360745259:key/00d875d4-34f8-48fe-a407-9ce0785d8630',
    REALM: 'na',
};
