import { deserializeQueryStringInput } from '../Url';
import { info } from '../Logger';
import { mapAmazonLocaleToIetf } from '../Localization';
import { marketplaceFromObfuscatedId } from '../../config/marketplaces';
export class GlobalState {
    constructor(location) {
        const inputs = deserializeQueryStringInput(location);
        if (!inputs.orderId)
            throw new Error('Missing required orderId input');
        if (!inputs.purchaseId)
            throw new Error('Missing required purchaseId input');
        if (!inputs.customerId)
            throw new Error('Missing required customerId input');
        if (!inputs.marketplaceId)
            throw new Error('Missing required marketplaceId input');
        if (!inputs.languageCode)
            throw new Error('Missing required languageCode input');
        if (!inputs.accountId)
            throw new Error('Missing required accountId input');
        if (!inputs.signature)
            throw new Error('Missing required signature input');
        info('GlobalState:unvalidatedInputs', {
            ...inputs,
            marketplace: marketplaceFromObfuscatedId(inputs.marketplaceId),
        });
        this.inputs = {
            orderId: inputs.orderId,
            purchaseId: inputs.purchaseId,
            customerId: inputs.customerId,
            marketplace: marketplaceFromObfuscatedId(inputs.marketplaceId),
            languageCode: inputs.languageCode,
            locale: mapAmazonLocaleToIetf(inputs.languageCode),
            commId: inputs.commId || '',
            accountId: inputs.accountId,
            signature: inputs.signature,
            source: inputs.source,
        };
        this.readOnlyMode = !Boolean(inputs.commId);
    }
    getSignatureValidationFields() {
        return {
            accountId: this.inputs.accountId,
            commId: this.inputs.commId,
            customerId: this.inputs.customerId,
            languageCode: this.inputs.languageCode,
            marketplace: this.inputs.marketplace,
            orderId: this.inputs.orderId,
            purchaseId: this.inputs.purchaseId,
            signature: this.inputs.signature,
        };
    }
}
export let globalState;
export const initialize = (location) => {
    if (!globalState)
        globalState = new GlobalState(location);
};
