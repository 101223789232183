import React, { Fragment, useState } from 'react';
import { css, cx } from 'emotion';
import { injectIntl } from 'react-intl';
import { Cards, DatePicker } from '@amzn/awsui-components-react';
import * as Logger from '../../util/Logger';
import { messages } from '../../intl';
import { sizing, typographyStyles } from '../../util/Styles';
export const convertPickerToDate = (datePickerString) => {
    const date = new Date(`${datePickerString}T00:00:00.000`);
    return isNaN(date.getTime()) ? undefined : date;
};
export const addDaysToDate = (days, date) => {
    const newDate = new Date(date.valueOf());
    newDate.setDate(date.getDate() + days);
    return newDate;
};
export const add15DaysToDate = addDaysToDate.bind(null, 15);
export const add31DaysToDate = addDaysToDate.bind(null, 31);
export const add61DaysToDate = addDaysToDate.bind(null, 61);
const headerStyle = css `
    margin-bottom: ${sizing.small};
`;
const subheaderStyle = css `
    margin-bottom: ${sizing.xsmall};
`;
const flexContainerStyle = css `
    align-items: center;
    display: flex;
    flex-wrap: wrap;
`;
const datePickerStyle = css `
    margin-right: ${sizing.large};
`;
export const dateResultsStyle = css `
    margin: ${sizing.small};
`;
export default injectIntl(({ intl }) => {
    const [date, setDate] = useState(undefined);
    return React.createElement(Cards, { header: React.createElement(Fragment, null,
            React.createElement("div", { className: cx(headerStyle, typographyStyles.fontSize3) }, intl.formatMessage(messages.RETURNS_SLA_CALCULATOR)),
            React.createElement("div", { className: cx(subheaderStyle, typographyStyles.fontSize1) }, intl.formatMessage(messages.RETURNS_CARRIER_DATE_PROMPT)),
            React.createElement("div", { className: flexContainerStyle },
                React.createElement("div", { className: datePickerStyle },
                    React.createElement(DatePicker, { locale: intl.locale, onChange: (event) => {
                            try {
                                Logger.info('ReturnSLACalculator:datepicker:changed', event.detail.value);
                                setDate(convertPickerToDate(event.detail.value));
                            }
                            catch (error) {
                                Logger.error('ReturnSLACalculator:datepicker:errorOnChange', { error, event });
                            }
                        } })),
                React.createElement("div", { className: dateResultsStyle },
                    React.createElement("div", null,
                        React.createElement("strong", null, intl.formatMessage(messages.RETURNS_14_DAY_SLA_DATE, {
                            date: (date ? intl.formatDate(add15DaysToDate(date)) : '-'),
                        }))),
                    React.createElement("div", null,
                        React.createElement("strong", null, intl.formatMessage(messages.RETURNS_30_DAY_SLA_DATE, {
                            date: (date ? intl.formatDate(add31DaysToDate(date)) : '-'),
                        }))),
                    React.createElement("div", null,
                        React.createElement("strong", null, intl.formatMessage(messages.RETURNS_60_DAY_SLA_DATE, {
                            date: (date ? intl.formatDate(add61DaysToDate(date)) : '-'),
                        })))))) });
});
