import React from 'react';
import { Cards } from '@amzn/awsui-components-react';
import { css } from 'emotion';
import { injectIntl } from 'react-intl';
import AbuseStats from './abuseStats';
import { colors, typographyStyles } from '../../util/Styles';
import ConcessionsToOrdersRatio from './concessionsToOrdersRatio';
import { messages } from '../../intl';

const abuseStatsStyles = css`
    flex-grow: 1;
`;

const activityDurationStyles = css`
    color: ${colors.grey550};
`;

const bodyStyles = css`
    display: flex;
`;

const concessionsToOrdersRatioStyles = css`
    margin-right: 20px;
    min-width: 200px;
`;

const titleStyles = css`
    display: flex;
    justify-content: space-between;
`;

const Title = ({ intl }) => (
    <Cards
        header={(
            <div className={titleStyles}>
                <span className={typographyStyles.fontSize3}>{intl.formatMessage(messages.concessionsActivity)}</span>
                <span className={activityDurationStyles}>{intl.formatMessage(messages.last12Months)}</span>
            </div>
        )}
    />
);

const defaultCustomerSummary = {
    concessions: {
        count: null,
        value: null,
    },
    orders: {
        count: null,
        value: null,
    },
    dnr: {
        count: null,
        value: null,
    },
    mdr: {
        count: null,
        value: null,
    },
    returnlessRefunds: {
        count: null,
        value: null,
    },
    returns: {
        count: null,
        value: null,
    },
};

export const InternalConcessionsActivity = ({
    currency,
    customerSummary,
    intl,
}) => {
    // eslint-disable-next-line no-param-reassign
    customerSummary = customerSummary || defaultCustomerSummary;
    return <div>
        <Title intl={intl} />
        <div className={bodyStyles}>
            <div className={concessionsToOrdersRatioStyles}>
                <ConcessionsToOrdersRatio
                    currency={currency}
                    concessionsCount={customerSummary.concessions.count}
                    concessionsValue={customerSummary.concessions.value}
                    ordersCount={customerSummary.orders.count}
                    ordersValue={customerSummary.orders.value}
                />
            </div>
            <div className={abuseStatsStyles}>
                <AbuseStats
                    currency={currency}
                    dnr={customerSummary.dnr}
                    mdr={customerSummary.mdr}
                    returnlessRefunds={customerSummary.returnlessRefunds}
                    returns={customerSummary.returns}
                />
            </div>

        </div>

    </div>;
};

export default injectIntl(InternalConcessionsActivity);
