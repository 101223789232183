import * as storage from '../Storage';
const AUTHENTICATED_CREDENTIALS_PREFIX = 'authCredentials.';
const UNAUTHENTICATED_CREDENTIALS_PREFIX = 'unauthCredentials.';
const STS_ACCESS_KEY = 'AccessKeyId';
const STS_SECRET_KEY = 'SecretAccessKey';
const STS_SESSION_TOKEN = 'SessionToken';
const STS_EXPIRATION = 'Expiration';
const getCredentials = (prefix) => ({
    accessKeyId: storage.get(`${prefix}${STS_ACCESS_KEY}`),
    secretAccessKey: storage.get(`${prefix}${STS_SECRET_KEY}`),
    sessionToken: storage.get(`${prefix}${STS_SESSION_TOKEN}`),
    expiration: storage.get(`${prefix}${STS_EXPIRATION}`),
});
const setCredentials = (prefix, credentials) => storage.putAll({
    [`${prefix}${STS_ACCESS_KEY}`]: credentials.AccessKeyId,
    [`${prefix}${STS_SECRET_KEY}`]: credentials.SecretAccessKey,
    [`${prefix}${STS_SESSION_TOKEN}`]: credentials.SessionToken,
    [`${prefix}${STS_EXPIRATION}`]: credentials.Expiration,
});
const hasStoredCredentials = (prefix) => storage.hasKeys([
    `${prefix}${STS_ACCESS_KEY}`,
    `${prefix}${STS_SECRET_KEY}`,
    `${prefix}${STS_SESSION_TOKEN}`,
    `${prefix}${STS_EXPIRATION}`,
]);
const storedCredentialsAreValid = (prefix) => {
    if (!hasStoredCredentials(prefix))
        return false;
    return Date.parse(getCredentials(prefix).expiration) > Date.now();
};
export const areCredentialsEqual = (leftCredentials, rightCredentials) => ([STS_ACCESS_KEY, STS_SECRET_KEY, STS_SESSION_TOKEN, STS_EXPIRATION].reduce((prior, key) => prior && (leftCredentials[key] === rightCredentials[key]), true));
const CREDENTIALS_403_COUNT = 'Credentials403Count';
export const getCredentials403Count = () => Number(storage.get(CREDENTIALS_403_COUNT)) || 0;
export const incrementCredentials403Count = () => storage.put(CREDENTIALS_403_COUNT, getCredentials403Count() + 1);
export const clearCredentials403Count = () => storage.remove(CREDENTIALS_403_COUNT);
export const getAuthenticatedCredentials = getCredentials
    .bind(null, AUTHENTICATED_CREDENTIALS_PREFIX);
export const setAuthenticatedCredentials = setCredentials
    .bind(null, AUTHENTICATED_CREDENTIALS_PREFIX);
export const storedAuthenticatedCredentialsAreValid = storedCredentialsAreValid
    .bind(null, AUTHENTICATED_CREDENTIALS_PREFIX);
export const getUnauthenticatedCredentials = getCredentials
    .bind(null, UNAUTHENTICATED_CREDENTIALS_PREFIX);
export const setUnauthenticatedCredentials = setCredentials
    .bind(null, UNAUTHENTICATED_CREDENTIALS_PREFIX);
export const storedUnauthenticatedCredentialsAreValid = storedCredentialsAreValid
    .bind(null, UNAUTHENTICATED_CREDENTIALS_PREFIX);
