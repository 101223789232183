import React from 'react';
import { injectIntl } from 'react-intl';
import Container from '../Container';
import { messages } from '../../intl';
import RelatedContacts from './relatedContacts';

const OrderSummary = ({
    intl,
    orderId,
    relatedContacts = [],
}) => (
    <Container
        header={intl.formatMessage(messages.aboutThisOrder)}
        description={orderId}
    >
        <RelatedContacts intl={intl} contacts={relatedContacts} />
    </Container>
);

export default injectIntl(OrderSummary);
