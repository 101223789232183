
export class LogQueue {
    constructor() {
        this.queuedLogEvents = [];
    }

    clearAll() { this.queuedLogEvents = []; }

    enqueue(logEvent) { return this.queuedLogEvents.push(logEvent); }

    dequeue(count) {
        const dequeuedLogEvents = this.peek(count);
        this.queuedLogEvents = this.queuedLogEvents.slice(count);
        return dequeuedLogEvents;
    }

    peek(count) { return this.queuedLogEvents.slice(0, count); }
}

export const logQueue = new LogQueue();

export const clearAll = logQueue.clearAll.bind(logQueue);
export const dequeue = logQueue.dequeue.bind(logQueue);
export const enqueue = logQueue.enqueue.bind(logQueue);
export const peek = logQueue.peek.bind(logQueue);
