import React from 'react';

const containerClassName = 'awsui-util-container';
const containerHeaderClassName = 'awsui-util-container-header';
const containerDescriptionClassName = 'awsui-util-container-header-description';
const containerFooterClassName = 'awsui-util-container-footer';

const Container = ({
    children,
    header,
    description = null,
    footer = null,
}) => (
    <div className={containerClassName}>
        <div className={containerHeaderClassName}>
            {header}
            {description && <div className={containerDescriptionClassName}>{description}</div>}
        </div>
        {children}
        { footer && <div className={containerFooterClassName}>{footer}</div>}
    </div>
);

export default Container;
