import { css } from 'emotion';
export const titleStyle = css `
    padding-top: 12px !important; /* "important" because Polaris overrides this style with specificity */
    /* we should be constructing the workflow from multiple cards, not trying to build the workflow inside a single card */
`;
export const disabledSelectedStyle = css `button {
    background: #ffc8a2 !important;
    color: #939fa0 !important;
}`;
export const buttonWrapperStyle = css `
    margin-right: 1rem;
`;
