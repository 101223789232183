import CloudWatchLogs from 'aws-sdk/clients/cloudwatchlogs';
import config from '../../config';
import { areCredentialsEqual } from '../Session/credentials';
import { ensureLoggingCredentials } from '../Session';

let cloudWatchLogsSDK;
let currentCredentials;

const shouldRecreateSDK = nextCredentials => (
    !cloudWatchLogsSDK || !areCredentialsEqual(currentCredentials, nextCredentials)
);

export const fetchSDK = async () => {
    const nextCredentials = await ensureLoggingCredentials();
    if (shouldRecreateSDK(nextCredentials)) {
        currentCredentials = nextCredentials;
        cloudWatchLogsSDK = new CloudWatchLogs({
            ...currentCredentials,
            region: config.AWS_REGION,
        });
    }
    return cloudWatchLogsSDK;
};

export const clearSDK = () => {
    cloudWatchLogsSDK = undefined;
};
