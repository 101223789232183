import { CloudWatchIngester } from './cloudWatchIngester';
import { logQueue } from './logQueue';
import { LOG_INGESTION_INTERVAL } from './constants';

const LOG_GROUP_NAME = 'BrowserClientLogGroup';

export class LogPublisher {
    // eslint-disable-next-line no-shadow
    constructor(contactID, sessionID, logGroupName, logQueue) {
        this.ingester = new CloudWatchIngester(contactID, sessionID, logGroupName);
        this.ingester.initialize();
        this.logQueue = logQueue;
        this.intervalID = setInterval(this.publish.bind(this), LOG_INGESTION_INTERVAL);
        this.MAX_LOG_EVENTS_TO_INGEST = 10000;
    }

    publish() {
        const logEventsToIngest = this.logQueue.peek(this.MAX_LOG_EVENTS_TO_INGEST);
        return this.ingester.ingest(logEventsToIngest)
            .then(() => this.logQueue.dequeue(logEventsToIngest.length))
            // eslint-disable-next-line no-console
            .catch(e => console.warn(`Failed to publish: ${e}`));
    }

    stopIngestion() {
        clearInterval(this.intervalID);
    }
}

let logPublisher;

export const initialize = ({ contactID, sessionID }) => {
    logPublisher = new LogPublisher(contactID, sessionID, LOG_GROUP_NAME, logQueue);
    return logPublisher.ingester;
};

export const publish = () => logPublisher.publish();

export const stopIngestion = () => logPublisher.stopIngestion();
