import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Spinner } from '@amzn/awsui-components-react';
import * as Logger from '../../../../util/Logger';
import { globalState } from '../../../../util/Session/globalState';
export const REFER_TO_ARI_MUTATION = gql `
    mutation ReferToARI($input: ARIReferralInput!){
        referToARI(input: $input)
    }
`;
;
;
export const DoARIReferral = ({ inputs, next, logEvent, }) => {
    const [doARIReferral, { loading, called }] = useMutation(REFER_TO_ARI_MUTATION, {
        variables: {
            ...globalState.getSignatureValidationFields(),
            input: {
                commId: globalState.inputs.commId,
                concessionType: inputs.selectedConcessionType,
                customerInput: {
                    customerId: globalState.inputs.customerId,
                    marketplace: globalState.inputs.marketplace,
                },
                isValidConcessionRequest: inputs.isValidConcessionRequest,
                orderId: globalState.inputs.orderId,
            },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            Logger.info('CAPWorkflow:DoARIReferral:succeeded', { inputs, data });
            logEvent('ARIReferral', 'SUCCEEDED');
            next({ ...inputs });
        },
        onError: (error) => {
            Logger.error('CAPWorkflow:DoARIReferral:failed', { inputs, error: error });
            next({ ...inputs });
        },
    });
    if (!called) {
        doARIReferral();
        return null;
    }
    ;
    if (loading)
        return React.createElement(Spinner, null);
    return null;
};
