import React, { Fragment } from 'react';
import { messages as untypedMessages } from '../../../../intl';
import { YesNoQuestion } from '../../../YesNoQuestion';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
;
;
export const ShouldConcessionTypeBeWrongItem = ({ intl, inputs, next, logEvent, disabled, }) => (React.createElement(Fragment, null,
    React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.WAS_WRONG_ITEM_RECEIVED)),
    React.createElement(YesNoQuestion, { disabled: disabled, intl: intl, onChange: (selection) => {
            const selectedConcessionType = selection === 'YES' ? 'wrongItem' : inputs.selectedConcessionType;
            next({ ...inputs, selectedConcessionType });
            logEvent('WasWrongItemReceived', selection);
        } })));
