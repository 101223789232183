import * as qs from 'qs';
import config from '../../config';
import { fetchNonce } from '../Api';
import * as storage from '../Storage';
import * as uuid from 'uuid';
import * as Logger from '../Logger';
const ISSUER = 'federate';
const NONCE_KEY = 'nonce';
const getNonce = () => storage.get(NONCE_KEY);
const setNonce = (nonce) => storage.put(NONCE_KEY, nonce);
// Exposed for test stubbing to work around JSDom navigation limitations
export const getWindowLocationSearch = () => window.location.search;
const redirectToFederate = () => {
    storage.put('state', JSON.stringify(qs.parse(location.search.substring(1))));
    const queryString = qs.stringify({
        client_id: config.FEDERATE_CLIENT_ID,
        nonce: getNonce(),
        redirect_uri: config.AUTHENTICATED_REDIRECT_URL,
        response_mode: 'query',
        response_type: 'code',
        scope: 'openid',
        state: '',
    });
    window.location.replace(`${config.FEDERATE_AUTHORIZATION_ENDPOINT}?${queryString}`);
};
export const renewAuthentication = () => fetchNonce()
    .then(({ nonce }) => {
    setNonce(nonce);
    redirectToFederate();
});
export const getCredentialsAuthorizationHeader = () => {
    const queryParams = qs.parse(getWindowLocationSearch().substring(1));
    const requestId = uuid.v4();
    Logger.info('Federate:getCredentialsAuthorizationHeader:withRequestId', requestId);
    return (queryParams.code && typeof queryParams.code === 'string' && getNonce())
        ? Promise.resolve(JSON.stringify({ requestId, issuer: ISSUER, code: queryParams.code, nonce: getNonce(), redirect: config.AUTHENTICATED_REDIRECT_URL }))
        : Promise.resolve(JSON.stringify({ requestId, issuer: ISSUER, redirect: config.AUTHENTICATED_REDIRECT_URL }));
};
