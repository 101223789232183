import * as Logger from '../../../../util/Logger';
const DE_POLICY_LINK = 'https://cs-assistant-eu.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=A1PA6795UKMFR9&locale=de-DE';
const ES_POLICY_LINK = 'https://cs-assistant-eu.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=A1RKKUPIHCS9HS&locale=es-ES';
const FR_POLICY_LINK = 'https://cs-assistant-eu.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=A13V1IB3VIYZZH&locale=fr-FR';
const IT_POLICY_LINK = 'https://cs-assistant-eu.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=APJ6JRA9NG5V4&locale=it-IT';
const UK_POLICY_LINK = 'https://cs-assistant-eu.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=A1F83G8C2ARO7P&locale=en-GB';
const US_POLICY_LINK = 'https://cs-assistant-na.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=ATVPDKIKX0DER&locale=en-US';
const CA_POLICY_LINK = 'https://cs-assistant-na.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=A2EUQ1WTGCTBG2&locale=en-CA';
const MX_POLICY_LINK = 'https://cs-assistant-na.amazon.com/home/fetch?query=cap+safelist&contentId=Tuw5rPLLGibhGVLwQd&marketplaceId=A1AM78C64UM0Y8&locale=es-MX';
const consultCASEPolicyByMarketplace = {
    DE: DE_POLICY_LINK,
    ES_PROD: ES_POLICY_LINK,
    ES_DEVO: ES_POLICY_LINK,
    FR: FR_POLICY_LINK,
    IN_PROD: '',
    IN_DEVO: '',
    IT_PROD: IT_POLICY_LINK,
    IT_DEVO: IT_POLICY_LINK,
    UK: UK_POLICY_LINK,
    US: US_POLICY_LINK,
    CA: CA_POLICY_LINK,
    MX_PROD: MX_POLICY_LINK,
    MX_DEVO: MX_POLICY_LINK,
};
const getConsultCASEPolicyLink = (marketplace) => {
    const link = consultCASEPolicyByMarketplace[marketplace];
    if (!link) {
        Logger.error('GetConsultCASEPolicyLink:UnconfiguredMarketplace', { marketplace });
    }
    return link;
};
export default getConsultCASEPolicyLink;
