import React from 'react';
import { useMutation, gql } from '@apollo/client';
import { Spinner } from '@amzn/awsui-components-react';
import * as Logger from '../../../../util/Logger';
import { globalState } from '../../../../util/Session/globalState';
export const SEND_ID_VERIFICATION_EMAIL = gql `
    mutation SendIDVerificationEmail($input: SendIDVerificationEmailInput!) {
        sendIDVerificationEmail(input: $input)
    }
`;
;
;
export const SendIDVerificationEmail = ({ inputs, logEvent, }) => {
    const [sendIDVerificationEmail, { loading, called }] = useMutation(SEND_ID_VERIFICATION_EMAIL, {
        variables: {
            ...globalState.getSignatureValidationFields(),
            input: {
                customerId: globalState.inputs.customerId,
                orderId: globalState.inputs.orderId,
                marketplace: globalState.inputs.marketplace,
            },
        },
        notifyOnNetworkStatusChange: true,
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            Logger.info('CAPWorkflow:SendIDVerificationEmail:succeeded', { inputs, data });
            logEvent('SendIDVerificationEmail', 'SUCCEEDED');
        },
        onError: (error) => {
            Logger.error('CAPWorkflow:SendIDVerificationEmail:failed', { inputs, error });
        },
    });
    if (!called) {
        sendIDVerificationEmail();
        return null;
    }
    ;
    if (loading)
        return React.createElement(Spinner, null);
    return null;
};
