import * as Logger from '../Logger';
export const instrumentPromise = (operationName, promiseFn) => (...args) => {
    const startTime = Date.now();
    Logger.enqueueStartedMessage(operationName);
    return promiseFn(...args)
        .then((result) => {
        Logger.enqueueSucceededMessage(operationName, startTime);
        return result;
    })
        .catch((error) => {
        Logger.enqueueFailedMessage(operationName, startTime);
        throw error;
    });
};
