import React, { Fragment, useState } from 'react';
import { Select } from '@amzn/awsui-components-react';
import { messages as untypedMessages } from '../../../../intl';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
const getSelectOptions = (intl) => ({
    itemMissing: {
        label: intl.formatMessage(messages.ITEM_MISSING),
        id: 'itemMissing',
    },
    dnr: {
        label: intl.formatMessage(messages.DELIVERED_NOT_RECEIVED),
        id: 'dnr',
    },
    partMissing: {
        label: intl.formatMessage(messages.PART_MISSING),
        id: 'partMissing',
    },
    wrongItem: {
        label: intl.formatMessage(messages.WRONG_ITEM),
        id: 'wrongItem',
    },
    returnRelated: {
        label: intl.formatMessage(messages.RETURN_RELATED),
        id: 'returnRelated',
    },
    damagedDefective: {
        label: intl.formatMessage(messages.DAMAGED_DEFECTIVE),
        id: 'damagedDefective',
    },
    preDelivery: {
        label: intl.formatMessage(messages.PRE_DELIVERY),
        id: 'preDelivery',
    },
});
export const SelectConcessionType = ({ intl, inputs, next, logEvent, disabled, }) => {
    const [selectedConcessionType, setSelectedConcessionType] = useState();
    return (React.createElement(Fragment, null,
        React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.REQUESTED_CONCESSION_TYPE)),
        React.createElement(Select, { onChange: (event) => {
                next({
                    ...inputs,
                    selectedConcessionType: event.detail.selectedId,
                });
                setSelectedConcessionType(event.detail.selectedId);
                logEvent('ConcessionTypeSelected', event.detail.selectedId);
            }, selectedOption: selectedConcessionType ? getSelectOptions(intl)[selectedConcessionType] : null, options: Object.values(getSelectOptions(intl)), placeholder: intl.formatMessage(messages.MAKE_A_SELECTION), disabled: disabled })));
};
