import React from 'react';
import { Cards } from '@amzn/awsui-components-react';
import { css, cx } from 'emotion';
import { injectIntl } from 'react-intl';
import { messages } from '../../intl';
import { colors, sizing, typographyStyles } from '../../util/Styles';

const footerStyle = css`
    display: flex;
    justify-content: space-between;
`;

const issueNamesStyle = cx(
    typographyStyles.fontSize3,
    css`
        padding-bottom: ${sizing.medium};
        padding-top: ${sizing.xsmall};
    `,
);

const orderNumberStyle = css`
    color: ${colors.grey550};
    padding-bottom: ${sizing.xsmall};
`;

const getConcessionIssuedStyle = isConcessionGiven => css`
    color: ${colors.green500};
    padding-right: ${sizing.small};
    visibility: ${isConcessionGiven ? 'visible' : 'hidden'};
`;

const getRemainingContactsStyle = hasMoreContacts => css`
    color: ${colors.grey550};
    visibility: ${hasMoreContacts ? 'visible' : 'hidden'};
`;

const getOrderWithCAPContactHeader = (intl, { id, contacts }) => (
    <div className={typographyStyles.fontSize2}>
        <div className={orderNumberStyle}>{id}</div>
        <div>
            {intl.formatDate(contacts[0].createdDate, {
                month: 'long',
                day: 'numeric',
                weekday: 'short',
                year: 'numeric',
                hour: 'numeric',
                minute: '2-digit',
                timeZoneName: 'short',
            })}
        </div>
    </div>
);

const getOrderWithCAPContactBody = (intl, { contacts, hasAssociatedConcession }) => {
    const remainingContacts = contacts.length - 1;
    const hasMoreContacts = remainingContacts > 0;
    return (
        <div>
            <div className={issueNamesStyle}>{contacts[0].reason}</div>
            <div className={footerStyle}>
                <div className={getConcessionIssuedStyle(hasAssociatedConcession)}>
                    {intl.formatMessage(messages.concessionIssued)}
                </div>
                <div className={getRemainingContactsStyle(hasMoreContacts)}>
                    {intl.formatMessage(messages.remainingContactsCount, {
                        count: remainingContacts,
                    })}
                </div>
            </div>
        </div>
    );
};

const OrdersWithCAPContact = ({ intl, orders }) => (
    <Cards
        cardDefinition={{
            header: getOrderWithCAPContactHeader.bind(null, intl),
            sections: [
                {
                    id: 'body',
                    content: getOrderWithCAPContactBody.bind(null, intl),
                },
            ],
        }}
        header={
            <span className={typographyStyles.fontSize3}>
                {intl.formatMessage(messages.pastCAPContacts)}
            </span>
        }
        items={orders ? orders.filter(order => order.contacts && order.contacts.length) : []}
    />
);

export default injectIntl(OrdersWithCAPContact);
