import { ConsoleSpanExporter, SimpleSpanProcessor } from '@opentelemetry/sdk-trace-base';
import { WebTracerProvider } from '@opentelemetry/sdk-trace-web';
import { FetchInstrumentation } from '@opentelemetry/instrumentation-fetch';
import { ZoneContextManager } from '@opentelemetry/context-zone';
import { AWSXRayIdGenerator } from '@opentelemetry/id-generator-aws-xray';
import { W3CTraceContextPropagator } from '@opentelemetry/core';

const provider = new WebTracerProvider({
    idGenerator: new AWSXRayIdGenerator(),
    registerInstrumentations: [
        new FetchInstrumentation({
            propagateTraceHeaderCorsUrls: [
                // `https://${config.API_HOSTNAME}/api/nonce`, // @TODO: fix API, this 401s
                // `https://${config.API_HOSTNAME}/api/credentials`,  // @TODO: fix API, this 401s
                // Note that /api/graphql is not included, see OTelLink.js
            ],
            clearTimingResources: true,
        }),
    ],
});

provider.addSpanProcessor(new SimpleSpanProcessor(new ConsoleSpanExporter()));

provider.register({
    contextManager: new ZoneContextManager(),
    propagator: new W3CTraceContextPropagator(),
});
