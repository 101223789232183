import React from 'react';
import { css } from 'emotion';
import { injectIntl } from 'react-intl';
import * as awsui from '@amzn/awsui-design-tokens/polaris';
import { messages } from '../../intl';

// https://polaris.a2z.com/fundamentals/guidelines/data_vis/data_vis_colors/#status-and-severity-palette
const getRiskBadgeColor = riskLevel =>
    ({
        HIGH: `${awsui.colorChartsStatusHigh}`,
        MEDIUM: `${awsui.colorChartsStatusMedium}`,
        LOW: `${awsui.colorChartsStatusNeutral}`,
        NONE: `${awsui.colorChartsStatusPositive}`,
    }[riskLevel] || `${awsui.colorChartsStatusNeutral}`);

const getRiskBadgeStyle = riskLevel => css`
    box-sizing: border-box;
    font-size: 1.2rem;
    line-height: 2.2rem;
    display: inline-block;
    border-radius: 1.6rem;
    padding: 0 8px;
    color: ${awsui.colorTextNotificationDefault};
    background-color: ${getRiskBadgeColor(riskLevel)};
`;

const RiskBadge = ({ riskLevel, intl }) => (
    <span className={getRiskBadgeStyle(riskLevel)}>
        {messages[riskLevel] ? intl.formatMessage(messages[riskLevel]) : riskLevel}
    </span>
);

export default injectIntl(RiskBadge);
