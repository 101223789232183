const marketplacesToObfuscatedIds = {
    DE: 'A1PA6795UKMFR9',
    ES_PROD: 'A1RKKUPIHCS9HS',
    ES_DEVO: 'AJZF8LZ1EJVJN',
    FR: 'A13V1IB3VIYZZH',
    IN_PROD: 'A21TJRUUN4KGV',
    IN_DEVO: 'A2XZLSVIQ0F4JT',
    IT_PROD: 'APJ6JRA9NG5V4',
    IT_DEVO: 'A3HOBANJMCMD83',
    US: 'ATVPDKIKX0DER',
    UK: 'A1F83G8C2ARO7P',
    MX_PROD: 'A1AM78C64UM0Y8',
    MX_DEVO: 'A3P3J5A7D2ZVXI',
    CA: 'A2EUQ1WTGCTBG2',
};
const marketplaceToRegionIds = {
    DE: '2',
    ES_PROD: '2',
    ES_DEVO: '2',
    FR: '2',
    IN_PROD: '4',
    IN_DEVO: '4',
    IT_PROD: '2',
    IT_DEVO: '2',
    US: '1',
    UK: '2',
    MX_PROD: '1',
    MX_DEVO: '1',
    CA: '1',
};
const obfuscatedIdsToMarketplaces = Object.entries(marketplacesToObfuscatedIds).reduce((map, [key, value]) => ({ ...map, [value]: key }), {});
export const marketplaceFromObfuscatedId = (obfuscatedMarketplaceId) => obfuscatedIdsToMarketplaces[obfuscatedMarketplaceId];
// https://w.amazon.com/bin/view/Regionid
export const regionIdFromMarketplace = (marketplace) => marketplaceToRegionIds[marketplace];
export const obfuscatedIdFromMarketplace = (marketplace) => marketplacesToObfuscatedIds[marketplace];
export default marketplacesToObfuscatedIds;
