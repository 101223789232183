import { cloneDeep, get, set } from 'lodash';
import * as Logger from '../Logger';

const MISSING_FIELD = 'DataRedactor:redactFieldsFromData:MissingField';

export const redactFieldPathsFromData = (data, fieldPathsToRedact, requestUUID) => {
    const redactedData = cloneDeep(data);
    fieldPathsToRedact.forEach(fieldPath => ((typeof get(redactedData, fieldPath) === 'undefined') ? Logger.info(MISSING_FIELD, { fieldPath, requestUUID }) : set(redactedData, fieldPath, 'REDACTED')));
    return redactedData;
};
