/**
 * This symbol is a unique, module-access-protected value which is non-replicable by callers.
 * Because it's not exported, and because of its inclusion in the Edge interfaces below,
 * the workflow framework users *must* use the functions below to declare edges. It prevents
 * us from just constructing POJOs with the appropariate `from` and `to` fields.
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Symbol
 *
 * Using the `edge` and `conditionalEdge` functions below will force TypeScript to infer the
 * correct types and enforce type constraints between the `from` component's `OutputContext`
 * and the `to` component's `InputContext`. This type enforcement is why we want to compel
 * edge construction using these functions.
 * @see https://i.amazon.com/issues/CAPTECH-4124?selectedConversation=d885614a-2a39-4edd-8ec9-c2db91b06c04
 */
const _symbol = Symbol.for('EdgeDeclaration');
export const edge = (edge) => ({
    ...edge,
    _symbol,
});
export const conditionalEdge = (edge) => ({
    ...edge,
    _symbol,
});
