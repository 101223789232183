let decoder;
let encoder;

export const decode = (input) => {
    if (decoder === undefined) {
        decoder = new window.TextDecoder();
    }
    return decoder.decode(input);
};

export const encode = (input) => {
    if (encoder === undefined) {
        encoder = new window.TextEncoder();
    }
    return encoder.encode(input);
};
