import * as uuid from 'uuid';
import { decryptCustomerId } from '@amzn/amazon-id';
import { dataWarehouseLogQueue } from './dataWarehouseLogQueue';
import { regionIdFromMarketplace, obfuscatedIdFromMarketplace } from '../../config/marketplaces';
import config from '../../config';
import { warn } from '.';

export const dataWarehouse = (name, message, metadata) => {
    // @TODO use TypeScript for type checking
    if (typeof message !== 'object') {
        return warn(
            'Logger:dataWarehouse:invalidMessage',
            `Invalid message "${message}" is not an object`,
        );
    }
    if (typeof metadata !== 'object') {
        return warn(
            'Logger:dataWarehouse:invalidMetadata',
            `Invalid metadata "${metadata}" is not an object`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata, 'provider') || typeof metadata.provider !== 'string') {
        return warn(
            'Logger:dataWarehouse:invalidProvider',
            `Invalid metadata "${metadata}" does not have "provider" string property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata, 'subject') || typeof metadata.subject !== 'string') {
        return warn(
            'Logger:dataWarehouse:invalidSubject',
            `Invalid metadata "${metadata}" does not have "subject" string property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata, 'dataset') || typeof metadata.dataset !== 'string') {
        return warn(
            'Logger:dataWarehouse:invalidDataset',
            `Invalid metadata "${metadata}" does not have "dataset" string property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata, 'schema') || typeof metadata.schema !== 'object') {
        return warn(
            'Logger:dataWarehouse:invalidSchema',
            `Invalid metadata "${metadata}" does not have "schema" object property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata.schema, 'version') || typeof metadata.schema.version !== 'object') {
        return warn(
            'Logger:dataWarehouse:invalidSchema',
            `Invalid metadata schema "${metadata.schema}" does not have "version" object property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata.schema.version, 'major') || typeof metadata.schema.version.major !== 'number') {
        return warn(
            'Logger:dataWarehouse:invalidSchema',
            `Invalid metadata schema version "${metadata.schema.version}" does not have "major" number property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata.schema.version, 'minor') || typeof metadata.schema.version.minor !== 'number') {
        return warn(
            'Logger:dataWarehouse:invalidSchema',
            `Invalid metadata schema version "${metadata.schema.version}" does not have "minor" number property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata.schema, 'string') || typeof metadata.schema.string !== 'string') {
        return warn(
            'Logger:dataWarehouse:invalidSchema',
            `Invalid metadata schema "${metadata.schema}" does not have "string" string property`,
        );
    }
    if (!Object.prototype.hasOwnProperty.call(metadata.schema, 'elements') || !Array.isArray(metadata.schema.elements)) {
        return warn(
            'Logger:dataWarehouse:invalidSchema',
            `Invalid metadata schema "${metadata.schema}" does not have "elements" array property`,
        );
    }

    // https://w.amazon.com/bin/view/EDX/Documentation/Schema#Defining_your_Schema
    const sdlSchemaNameChars = /^[a-z0-9.-]+$/;
    // metadata.provider and metadata.subject are used to build the schema name for DataCraft and EDX
    if (!sdlSchemaNameChars.test(metadata.provider)) {
        return warn(
            'Logger:dataWarehouse:invalidProvider',
            `Invalid characters in provider "${metadata.provider}" for message "${name}"`,
        );
    }
    if (!sdlSchemaNameChars.test(metadata.subject)) {
        return warn(
            'Logger:dataWarehouse:invalidSubject',
            `Invalid characters in subject "${metadata.subject}" for message "${name}"`,
        );
    }
    if (!sdlSchemaNameChars.test(metadata.dataset)) {
        return warn(
            'Logger:dataWarehouse:invalidDataset',
            `Invalid characters in dataset "${metadata.dataset}" for message "${name}"`,
        );
    }

    console.info(name, message, metadata);
    dataWarehouseLogQueue.enqueue({
        message: JSON.stringify({
            name,
            level: 'INFO',
            body: message,
            dataWarehouse: metadata,
        }),
        timestamp: Date.now(),
    });
};

export const dataWarehouseEvent = ({
    namespace,
    key,
    value,
    marketplace,
    orderId,
    commId,
    sessionId,
    traceId = '',
}) => dataWarehouse(`Events:${namespace}:${key}`,
    {
        eventUuid: uuid.v4(),
        namespace,
        key,
        value,
        marketplaceId: decryptCustomerId(obfuscatedIdFromMarketplace(marketplace)),
        orderId,
        commId,
        sessionId,
        regionId: regionIdFromMarketplace(marketplace),
        traceId,
    },
    {
        provider: 'capwebsite',
        subject: 'events',
        dataset: config.REALM,
        unitPeriod: 'hour',
        schema: {
            version: {
                major: 2,
                minor: 0,
            },
            string: 'elements: [{name: "event_uuid", type:{base:string, max_length:36}},{name: "event_datetime_utc", type:{base:timestamp, timestamp_mask:"yyyy-MM-dd\'T\'HH:mm:ss"}},{name: "namespace", type:{base:string, max_length:200}},{name: "key", type:{base:string, max_length:200}},{name: "value", type:{base:string, max_length:200}},{name: "marketplace_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "order_id", type:{base:string, max_length:19}},{name: "comm_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "session_id", type:{base:string, max_length:36}},{name: "trace_id", type:{base:string, max_length:32}},{name: "region_id", type:{base:int, min_value:-32768, max_value:32767}}],',
            elements: [
                '$.message.body.eventUuid',
                '$.timestampAndes',
                '$.message.body.namespace',
                '$.message.body.key',
                '$.message.body.value',
                '$.message.body.marketplaceId',
                '$.message.body.orderId',
                '$.message.body.commId',
                '$.message.body.sessionId',
                '$.message.body.traceId',
                '$.message.body.regionId',
            ],
        },
    },
);
