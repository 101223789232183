import { enqueue } from './logQueue';
import * as Timer from './timer';

Timer.initialize(Date.now());

const STATUS_FAILED = 'FAILED';
const STATUS_STARTED = 'STARTED';
const STATUS_SUCCEEDED = 'SUCCEEDED';
const INFO = 'INFO';
const WARN = 'WARN';
const ERROR = 'ERROR';

const formatMessage = message => (
    (message instanceof Error) ? `${message}: ${message.stack}` : message
);

const enqueueMessageWithLogLevel = (level, name, message) => {
    const LOG_FUNCTION_SELECTOR = {
        [ERROR]: console.error,
        [WARN]: console.warn,
        [INFO]: console.info,
    };

    LOG_FUNCTION_SELECTOR[level](name, message);
    enqueue({
        message: JSON.stringify({
            name,
            level,
            body: formatMessage(message),
        }),
        timestamp: Date.now(),
    });
};

export const error = (name, message) => enqueueMessageWithLogLevel(ERROR, name, message);
export const warn = (name, message) => enqueueMessageWithLogLevel(WARN, name, message);
export const info = (name, message) => enqueueMessageWithLogLevel(INFO, name, message);

export const success = (name, duration) => info(name, {
    status: STATUS_SUCCEEDED,
    duration,
});

export const enqueueSucceededMessage = (name, startTime) => info(name, {
    status: STATUS_SUCCEEDED,
    duration: Timer.getMillisecondsFrom(startTime),
});

export const enqueueDurationFromAppStartMessage = name => info(name, {
    duration: Timer.getMillisecondsFromAppStart(),
});

export const failure = (name, duration, errorObject) => {
    info(name, {
        status: STATUS_FAILED,
        duration,
    });
    error(name, errorObject);
};

export const enqueueFailedMessage = (name, startTime, errorObject) => {
    info(name, {
        status: STATUS_FAILED,
        duration: Timer.getMillisecondsFrom(startTime),
    });
    error(name, errorObject);
};

export const enqueueStartedMessage = name => info(name, {
    status: STATUS_STARTED,
});

// eslint-disable-next-line import/no-cycle
export { dataWarehouse, dataWarehouseEvent } from './dataWarehouse';
