import React from 'react';
import { Cards } from '@amzn/awsui-components-react';
import { css, cx } from 'emotion';
import { injectIntl } from 'react-intl';
import { messages } from '../../intl';
import { cardHeaderStyles, orderCountStyles } from './styles';
import { sizing, typographyStyles } from '../../util/Styles';
import { isNullOrUndefined } from './abuseStats';

const ratioStyles = cx(
    typographyStyles.fontSize4,
    css`
        padding-top: ${sizing.small};
        padding-bottom: ${sizing.small};
    `,
);

const statsSectionStyles = css`
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding-bottom: ${sizing.small};
`;

const concessionsStats = (currency, intl, item) => (
    <div className={statsSectionStyles}>
        <div>
            <div>{intl.formatMessage(messages.concessions)}</div>
            <div className={orderCountStyles}>
                {!isNullOrUndefined(item.concessionsCount) && intl.formatMessage(messages.ordersCount, {
                    count: intl.formatNumber(item.concessionsCount),
                })}
            </div>
        </div>
        <div className={typographyStyles.fontSize3}>
            {!isNullOrUndefined(item.concessionsCount) ? intl.formatNumber(item.concessionsValue, { style: 'currency', currency }) : intl.formatMessage(messages.null)}
        </div>
    </div>
);

const ordersStats = (currency, intl, item) => (
    <div className={statsSectionStyles}>
        <div>
            <div>{intl.formatMessage(messages.orders)}</div>
            <div className={orderCountStyles}>
                {!isNullOrUndefined(item.ordersCount) && intl.formatMessage(messages.ordersCount, {
                    count: intl.formatNumber(item.ordersCount),
                })}
            </div>
        </div>
        <div className={typographyStyles.fontSize3}>
            {!isNullOrUndefined(item.ordersValue) ? intl.formatNumber(item.ordersValue, { style: 'currency', currency }) : intl.formatMessage(messages.null)}
        </div>
    </div>
);

const formatConcessionsToOrderRatio = (intl, concessionsValue, ordersValue) => {
    if (isNullOrUndefined(concessionsValue) || isNullOrUndefined(ordersValue)) {
        return intl.formatMessage(messages.null);
    }

    const concessionRatio = concessionsValue / ordersValue;

    if (!Number.isFinite(concessionRatio)) {
        return intl.formatNumber(0, {
            maximumFractionDigits: 2,
            style: 'percent',
        });
    }
    return intl.formatNumber(concessionRatio, {
        maximumFractionDigits: 2,
        style: 'percent',
    });
};

const ConcessionsToOrdersRatio = ({
    concessionsCount,
    concessionsValue,
    currency,
    intl,
    ordersCount,
    ordersValue,
}) => (
    <Cards
        cardDefinition={{
            header: item => <div className={cardHeaderStyles}>{item.title}</div>,
            sections: [{
                id: 'body',
                content: item => (
                    <div>
                        <div className={ratioStyles}>
                            {formatConcessionsToOrderRatio(intl, item.concessionsValue, item.ordersValue)}
                        </div>
                        {concessionsStats(currency, intl, item)}
                        {ordersStats(currency, intl, item)}
                    </div>
                ),
            }],
        }}
        cardsPerRow={[{ cards: 1 }]}
        items={[{
            concessionsCount,
            concessionsValue,
            ordersCount,
            ordersValue,
            title: intl.formatMessage(messages.concessionsToOrdersRatio),
        }]}
    />
);

export default injectIntl(ConcessionsToOrdersRatio);
