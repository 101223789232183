import React, { Fragment } from 'react';
import { messages as untypedMessages } from '../../../../intl';
import getConsultCASEPolicyLink from './getConsultCASEPolicyLink';
import { typographyStyles } from '../../../../util/Styles';
import { globalState } from '../../../../util/Session/globalState';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
export const ShowConsultCASEPolicy = ({ intl, logEvent, }) => {
    logEvent('PolicyFetched', 'GRANT_CONCESSION_AND_ADD_CUSTOMER_TO_SAFELIST');
    return (React.createElement(Fragment, null,
        React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.RECOMMENDED_POLICY_STAGE)),
        React.createElement("a", { className: typographyStyles.fontSize1, href: getConsultCASEPolicyLink(globalState.inputs.marketplace), onClick: () => logEvent('PolicyClicked', 'GRANT_CONCESSION_AND_ADD_CUSTOMER_TO_SAFELIST'), rel: "noopener noreferrer" // See https://mathiasbynens.github.io/rel-noopener
            , target: "_blank" }, intl.formatMessage(messages.GRANT_CONCESSION_AND_ADD_CUSTOMER_TO_SAFELIST))));
};
