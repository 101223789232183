import React, { useState } from 'react';
import { Button } from '@amzn/awsui-components-react';
import { messages as untypedMessages } from '../../intl';
import { buttonWrapperStyle, disabledSelectedStyle } from '../CAPWorkflow/styles';
const messages = untypedMessages;
export const YesNoQuestion = ({ intl, initialSelection, disabled, onChange, }) => {
    const [selection, setSelection] = useState(initialSelection);
    return (React.createElement("div", null,
        React.createElement("span", { className: buttonWrapperStyle, role: "radio", "aria-checked": selection === 'YES', "aria-disabled": Boolean(disabled) },
            React.createElement(Button, { className: selection === 'YES' && disabled ? disabledSelectedStyle : '', variant: selection === 'YES' ? 'primary' : 'normal', disabled: Boolean(disabled), onClick: () => {
                    setSelection('YES');
                    onChange('YES');
                } }, intl.formatMessage(messages.YES))),
        React.createElement("span", { className: buttonWrapperStyle, role: "radio", "aria-checked": selection === 'NO', "aria-disabled": Boolean(disabled) },
            React.createElement(Button, { className: selection === 'NO' && disabled ? disabledSelectedStyle : '', variant: selection === 'NO' ? 'primary' : 'normal', disabled: Boolean(disabled), onClick: () => {
                    setSelection('NO');
                    onChange('NO');
                } }, intl.formatMessage(messages.NO)))));
};
