import qs from 'qs';
import pick from 'lodash/pick';
import difference from 'lodash/difference';
import * as Logger from '../Logger';
export const federateQSExpected = ['state', 'code'];
export const capQSExpected = ['orderId', 'purchaseId', 'customerId', 'marketplaceId', 'languageCode', 'commId', 'accountId', 'signature',];
export const defaultInputFields = { source: 'default' };
export const setQueryString = (newQSParams) => {
    const queryString = qs.stringify(newQSParams);
    const relativeURL = `${window.location.pathname}?${queryString}`;
    window.history.replaceState({}, '', relativeURL);
};
const deserializeQueryString = (location, expectedFields, optionalFields) => {
    const input = qs.parse(location.search.substring(1));
    const sanitizedInput = { ...pick(input, [...expectedFields, ...optionalFields]) };
    const missingFields = difference(expectedFields, Object.keys(sanitizedInput));
    if (missingFields.length > 0) {
        Logger.warn('Url:deserializeQueryStringInput:missingFields', `Missing fields: ${missingFields} given sanitized input: ${JSON.stringify(sanitizedInput)}`);
    }
    return sanitizedInput;
};
export const deserializeQueryStringInput = (location) => ({
    ...defaultInputFields,
    ...deserializeQueryString(location, capQSExpected, Object.keys(defaultInputFields)),
});
export const getFederateCodeFromQueryString = (location) => (deserializeQueryString(location, federateQSExpected, []).code);
