import { decryptCustomerId } from '@amzn/amazon-id';
import { dataWarehouseEvent } from '../../util/Logger';
;
export const getLogEvent = ({ commId, marketplace, orderId, sessionId }) => (key, value) => dataWarehouseEvent({
    namespace: 'PolicyGuidance',
    key,
    value,
    marketplace,
    orderId,
    commId: decryptCustomerId(commId),
    sessionId,
});
export const once = (fn) => {
    let result = null;
    return (...args) => {
        if (result === null) {
            result = fn(...args);
        }
        return result;
    };
};
