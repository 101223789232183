import aws4 from 'aws4';
import config from '../../config';
import { fetchWithUnlimitedRetries } from './fetch';

const createSig4Fetch = credentials => (
    (uri, options) => {
        const { hostname, pathname } = new URL(uri);

        return fetchWithUnlimitedRetries(uri, {
            ...options,
            ...aws4.sign({
                hostname,
                path: pathname,
                region: config.AWS_REGION,
                headers: {
                    ...options.headers,
                },
                body: options.body,
                method: options.method,
                service: 'execute-api',
            }, credentials),
        });
    }
);

export default createSig4Fetch;
