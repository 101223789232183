import React from 'react';
import { css } from 'emotion';
import { injectIntl } from 'react-intl';
import { colors, sizing } from '../../util/Styles';
import { messages } from '../../intl';

const titleStyle = css`
    color: ${colors.grey550};
    text-transform: uppercase;
`;

const contactStyle = css`
    padding-top: ${sizing.medium};
`;

const Contact = ({
    intl,
    createdDate,
    reason,
}) => (
    <div className={contactStyle}>
        <strong>
            {intl.formatDate(createdDate, {
                month: 'long',
                day: 'numeric',
                weekday: 'short',
                year: 'numeric',
            })}
        </strong>
        <div className={titleStyle}>
            {intl.formatTime(createdDate, {
                timeZoneName: 'short',
            })}
        </div>
        {reason}
    </div>
);

const RelatedContacts = ({ intl, contacts }) => (
    <div>
        <div className={titleStyle}>
            {intl.formatMessage(
                messages.relatedContactsCount,
                { count: contacts ? contacts.length : 0 },
            )}
        </div>
        { contacts && contacts.map(({ createdDate, reason }) => (
            <Contact
                key={createdDate}
                intl={intl}
                createdDate={createdDate}
                reason={reason}
            />
        ))}
    </div>
);

export default injectIntl(RelatedContacts);
