import React, { Fragment, useState } from 'react';
import { messages as untypedMessages } from '../../../../intl';
import { Button } from '@amzn/awsui-components-react';
import { buttonWrapperStyle, disabledSelectedStyle } from '../../styles';
import { typographyStyles } from '../../../../util/Styles';
const messages = untypedMessages;
;
;
;
const getReasonText = {
    dnr: {
        descriptor: messages.DELIVERED_NOT_RECEIVED_DESCRIPTION,
    },
    itemMissing: {
        descriptor: messages.ITEM_MISSING_DESCRIPTION,
        body: [messages.ITEM_MISSING_SINGLE_ITEM, messages.ITEM_MISSING_MULTIPLE_ITEMS, messages.ITEM_MISSING_PARTIAL_QUANTITY],
    },
    partMissing: {
        descriptor: messages.PARTS_MISSING_DESCRIPTION,
        body: [messages.PARTS_MISSING_MAIN_ITEM, messages.PARTS_MISSING_MULTI_ITEM],
    },
    wrongItem: {
        descriptor: messages.WRONG_ITEM_DESCRIPTION,
        body: [messages.WRONG_ITEM_DIFFERENT_DETAILS, messages.WRONG_ITEM_DIFFERENT_PRODUCT_GROUP, messages.WRONG_ITEM_USED_PRODUCT, messages.WRONG_ITEM_FRAUDULENT_PRODUCT],
    },
    returnRelated: {
        descriptor: messages.RETURN_RELATED_DESCRIPTION,
        body: [messages.RETURN_RELATED_MDR, messages.RETURN_RELATED_FLR, messages.RETURN_RELATED_NSR],
    },
    damagedDefective: {
        descriptor: messages.DAMAGED_DEFECTIVE_DESCRIPTION,
    },
    preDelivery: {
        descriptor: messages.PRE_DELIVERY_DESCRIPTION,
    },
};
export const ShowConcessionContactReason = ({ intl, inputs, next, logEvent, disabled, reset, }) => {
    var _a;
    const [selection, setSelection] = useState('');
    const [concessionType] = useState(inputs.selectedConcessionType);
    return (React.createElement(Fragment, null,
        React.createElement("p", { className: typographyStyles.fontSize2 }, intl.formatMessage(getReasonText[concessionType].descriptor)),
        React.createElement("ul", { className: typographyStyles.fontSize2 }, (_a = getReasonText[concessionType].body) === null || _a === void 0 ? void 0 : _a.map((message, i) => (React.createElement("li", { key: i }, intl.formatMessage(message))))),
        React.createElement("p", { className: typographyStyles.fontSize2 }, intl.formatMessage(messages.DOES_DESCRIBE_CUSTOMER_ISSUE)),
        React.createElement("div", null,
            React.createElement("span", { className: buttonWrapperStyle, role: "radio", "aria-checked": selection === 'YES', "aria-disabled": Boolean(disabled) },
                React.createElement(Button, { className: selection === 'YES' && disabled ? disabledSelectedStyle : '', variant: selection === 'YES' ? 'primary' : 'normal', disabled: Boolean(disabled), onClick: () => {
                        setSelection('YES');
                        next({ ...inputs });
                        logEvent('DoesDescribeCustomerIssue', 'true');
                    } }, intl.formatMessage(messages.YES))),
            React.createElement("span", { className: buttonWrapperStyle, role: "radio", "aria-checked": selection === 'NO', "aria-disabled": Boolean(disabled) },
                React.createElement(Button, { className: selection === 'NO' && disabled ? disabledSelectedStyle : '', variant: selection === 'NO' ? 'primary' : 'normal', disabled: Boolean(disabled), onClick: () => {
                        setSelection('NO');
                        if (reset)
                            reset();
                        logEvent('DoesDescribeCustomerIssue', 'false');
                    } }, intl.formatMessage(messages.NO_RESET))))));
};
