import { assign } from 'xstate';
;
export const startWorkflowWith = (stepComponent, initialContext) => ({
    initial: stepComponent.name,
    context: {
        componentContext: initialContext,
        priorComponents: new Set(),
    }
});
export const state = (edges) => edges.reduce((stateConfig, edge) => {
    var _a, _b;
    return ({
        ...stateConfig,
        [edge.from.name]: {
            on: {
                CONTINUE: [
                    ...(((_b = (_a = stateConfig[edge.from.name]) === null || _a === void 0 ? void 0 : _a.on) === null || _b === void 0 ? void 0 : _b.CONTINUE) || []),
                    {
                        target: edge.to.name,
                        cond: edge.condition
                            ? (_, event) => (edge.condition(event.output))
                            : undefined,
                        actions: assign({
                            componentContext: (_, event) => event.output,
                            priorComponents: (context, _) => (new Set([
                                ...context.priorComponents,
                                edge.from.name,
                            ]))
                        }),
                    },
                ]
            }
        },
    });
}, {});
export const sinkState = (stepComponent) => ({
    [stepComponent.name]: {},
});
