import React, { Fragment } from 'react';
import { YesNoQuestion } from '../../../YesNoQuestion';
import { titleStyle } from '../../styles';
import { messages } from '../../../../intl';
;
;
export const IsMultipleDamagedDefectiveItems = ({ intl, inputs, next, logEvent, disabled, }) => (React.createElement(Fragment, null,
    React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.DAMAGED_DEFECTIVE_MULTIPLE_ITEMS)),
    React.createElement(YesNoQuestion, { disabled: disabled, intl: intl, onChange: (selection) => {
            next({ ...inputs, isDamagedDefectiveItems: selection === 'YES', selectedConcessionType: 'damagedDefective' });
            logEvent('IsMultipleDamagedDefectiveItemsAnswered', selection);
        } })));
