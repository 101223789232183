export default {
    API_HOSTNAME: 'eu.cap.cs.a2z.com',
    AUTHENTICATED_REDIRECT_URL: 'https://www.eu.cap.cs.a2z.com/',
    AWS_REGION: 'eu-west-1',
    FEDERATE_CLIENT_ID: 'CAPWebsite-Prod-EU',
    FEDERATE_AUTHORIZATION_ENDPOINT: 'https://idp.federate.amazon.com/api/oauth2/v1/authorize',
    GLOBAL_IDENTITY_CLIENT_ID: 'CAPWebsite-prod-eu',
    GLOBAL_IDENTITY_LOGIN_URL: 'https://eu.amzheimdall.com/login',
    KMS_KEY_ARN: 'arn:aws:kms:eu-west-1:574975901926:key/01ff2942-398a-4b94-8ffc-e6e519f8fa72',
    REALM: 'eu',
};
