import { ApolloLink, Observable, gql } from '@apollo/client';

import * as crypto from '../Crypto';
import * as logger from '../Logger';

const ROOT_TYPE_NAME = 'Query';
const ENCRYPTED_FIELD_TYPE_NAME = 'EncryptedString';

const addTypeIntrospectionToOperation = (operation) => {
    // "definitions[0].selectionSet.selections" is the set of fields composing the body of the query
    // e.g. __schema in the query below
    operation.query.definitions[0].selectionSet.selections.push(
        // we need triply-nested ofType to support a non-null list of non-nullables
        ...gql`
            query {
                __schema {
                    types {
                        name
                        fields {
                            name
                            type {
                                name
                                kind
                                ofType {
                                    name
                                    kind
                                    ofType {
                                        name
                                        kind
                                        ofType {
                                            name
                                            kind
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `.definitions[0].selectionSet.selections,
    );
};

const findFieldType = (fieldTypeName, allTypes) => allTypes.find(type => type.name === fieldTypeName);

const removeDuplicatePaths = (paths) => {
    const temp = {};

    return paths.filter((a) => {
        const hasProp = Object.prototype.hasOwnProperty.call(temp, a);
        temp[a] = hasProp;
        return !hasProp;
    });
};

const getPathsFromList = ({ field, thisPath, paths, data, unboxedType }) => {
    if (unboxedType.name) {
        const parent = thisPath.reduce((value, key) => (value ? value[key] : null), data);
        if (!parent || !parent[field.name]) return paths; // field is not in data set
        const listLength = parent[field.name].length;

        if (unboxedType.name === ENCRYPTED_FIELD_TYPE_NAME) {
            const listElementPaths = [...Array(listLength).keys()].map(
                i => [...thisPath, field.name, i],
            );
            return [...paths, ...listElementPaths];
        }
        const unflattenedListElementPaths = [...Array(listLength).keys()].map(
            i => getEncryptedFieldPaths(unboxedType.name, data, [...thisPath, field.name, i], paths), // eslint-disable-line
        );
        // workaround for flatMap support
        const listElementPaths = [].concat(...unflattenedListElementPaths);
        return [...paths, ...listElementPaths];
    }
};

const getEncryptedFieldPaths = (thisTypeName, data, thisPath = [], allPaths = []) => {
    if (!data || !data.__schema || !data.__schema.types) {
        logger.error('KmsDecryptionLink:request', { message: 'data.__schema.types is missing' });
        return allPaths;
    }

    const thisType = findFieldType(thisTypeName, data.__schema.types);
    if (!thisType.fields) return allPaths;

    const pathsWithDuplicates = thisType.fields.reduce((paths, field) => {
        if (field.type.name) { // Scalar
            if (field.type.name === ENCRYPTED_FIELD_TYPE_NAME) { // GraphQL Type - EncryptedString
                return [...paths, [...thisPath, field.name]];
            }
            return getEncryptedFieldPaths(field.type.name, data, [...thisPath, field.name], paths);
        }

        if (field.type.kind === 'NON_NULL') {
            if (field.type.ofType.name) { // non-null Scalar
                if (field.type.ofType.name === ENCRYPTED_FIELD_TYPE_NAME) { // GraphQL Type - EncryptedString!
                    return [...paths, [...thisPath, field.name]];
                }
                return getEncryptedFieldPaths(field.type.ofType.name, data, [...thisPath, field.name], paths);
            }

            if (field.type.ofType.kind === 'LIST') {
                if (field.type.ofType.ofType.kind === 'NON_NULL') { // GraphQL Type - [EncryptedString!]!
                    return getPathsFromList({ field, thisPath, paths, data, unboxedType: field.type.ofType.ofType.ofType });
                }
                return getPathsFromList({ field, thisPath, paths, data, unboxedType: field.type.ofType.ofType }); // GraphQL Type - [EncryptedString]!
            }
        }

        if (field.type.kind === 'LIST') {
            if (field.type.ofType.kind === 'NON_NULL') { // GraphQL Type - [EncryptedString!]
                return getPathsFromList({ field, thisPath, paths, data, unboxedType: field.type.ofType.ofType });
            }
            return getPathsFromList({ field, thisPath, paths, data, unboxedType: field.type.ofType }); // GraphQL Type - [EncryptedString]
        }

        throw new Error('Unknown field object shape', field);
    }, allPaths);

    // @TODO write a test to document how duplicate paths occur
    return removeDuplicatePaths(pathsWithDuplicates);
};

const getPromisesToDecryptFields = result => getEncryptedFieldPaths(
    ROOT_TYPE_NAME, result.data,
).reduce((promises, fieldPathArray) => {
    const ciphertext = fieldPathArray.reduce((value, key) => (value ? value[key] : null), result.data);

    if (!ciphertext) return promises;

    const decryptFieldEventName = `KmsDecryptionLink:decrypt:${fieldPathArray.join('.')}`;
    const startTime = Date.now();
    logger.enqueueStartedMessage(decryptFieldEventName);

    const promise = crypto.decrypt(ciphertext)
        .then(plaintext => (
            fieldPathArray.reduce(({ count, value }, key) => {
                if (count === fieldPathArray.length) {
                    // eslint-disable-next-line no-param-reassign
                    value[key] = plaintext;
                    return plaintext;
                }
                return { count: count + 1, value: value[key] };
            }, { count: 1, value: result.data })
        ))
        .then(() => logger.enqueueSucceededMessage(decryptFieldEventName, startTime))
        .catch((err) => {
            logger.enqueueFailedMessage(decryptFieldEventName, startTime, err);
            throw err;
        });

    return [...promises, promise];
}, []);

const isMutation = operation => operation.query.definitions[0].operation === 'mutation';

const getNoopObservable = (operation, forward) => new Observable((observer) => {
    const subscription = forward(operation).subscribe({
        next: (result) => {
            observer.next(result);
            observer.complete(result);
        },
        error: err => observer.error(err),
    });
    return (() => {
        if (subscription) return subscription.unsubscribe();
    });
});

const DECRYPTION_EVENT_NAME = 'KmsDecryptionLink:request';
export class KmsDecryptionLink extends ApolloLink {
    request(operation, forward) {
        if (isMutation(operation)) {
            return getNoopObservable(operation, forward);
        }

        addTypeIntrospectionToOperation(operation);

        return new Observable((observer) => {
            const subscription = forward(operation).subscribe({
                next: (result) => {
                    const startTime = Date.now();
                    logger.enqueueStartedMessage(DECRYPTION_EVENT_NAME);
                    return Promise.all(getPromisesToDecryptFields(result))
                        .then(() => {
                            logger.enqueueSucceededMessage(DECRYPTION_EVENT_NAME, startTime);
                            observer.next(result);
                            observer.complete();
                        })
                        .catch((err) => {
                            logger.enqueueFailedMessage(DECRYPTION_EVENT_NAME, startTime, err);
                            observer.error(err);
                        });
                },
                error: (err) => {
                    observer.error(err);
                },
            });

            return (() => {
                if (subscription) return subscription.unsubscribe();
            });
        });
    }
}
