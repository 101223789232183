import React, { Fragment } from 'react';
import { messages as untypedMessages } from '../../../../intl';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
export const ShowApplyConsumerPolicy = ({ intl, logEvent, }) => {
    logEvent('PolicyFetched', 'APPLY_CONSUMER_POLICY');
    return React.createElement(Fragment, null,
        React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.RECOMMENDED_POLICY_STAGE)),
        React.createElement("p", null, intl.formatMessage(messages.APPLY_CONSUMER_POLICY)));
};
