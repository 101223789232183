// ICU plural message format: https://formatjs.io/guides/message-syntax/#plural-format
const toICUPluralMessage = (message) => `{${message.value.param}, plural,
${Object.entries(message.value.pluralItems)
    .map(([pluralKey, pluralMessage]) => `${pluralKey} {${pluralMessage}}`)
    .join('\n')}}`;
// ICU select message format: https://formatjs.io/guides/message-syntax/#select-format
const toICUSelectMessage = (message) => `{${message.value.param}, select,
${Object.entries(message.value.selectItems)
    .map(([selectKey, selectMessage]) => `${selectKey} {${selectMessage}}`)
    .join('\n')}}`;
const toICUMessage = (message) => {
    if (message && message.value && message.value.pluralItems)
        return toICUPluralMessage(message);
    if (message && message.value && message.value.selectItems)
        return toICUSelectMessage(message);
    if (message && message.value && typeof message.value === 'string')
        return message.value;
    if (typeof message === 'string')
        return message;
    throw new Error('Unrecognized message schema');
};
// Puff-J File Format: https://w.amazon.com/index.php/INTech/Panther/Architecture/PantherFileFormat/JSON
// React-intl ICU message format: https://github.com/formatjs/react-intl/blob/master/docs/Components.md#message-syntax
export const toICUMessages = (puffj) => (Object.entries(puffj.resources).reduce((prior, [messageId, message]) => ({ ...prior, [messageId]: toICUMessage(message) }), {}));
// React-intl ICU message descriptor format: https://github.com/formatjs/react-intl/blob/master/docs/Components.md#message-descriptor
export const toICUMessageDescriptors = (puffj) => (Object.entries(puffj.resources).reduce((prior, [messageId, message]) => ({
    ...prior,
    [messageId]: {
        id: messageId,
        defaultMessage: toICUMessage(message),
    },
}), {}));
