import { ApolloLink } from '@apollo/client';
import { context, trace } from '@opentelemetry/api';
import { v4 as uuid } from 'uuid';
import { regionIdFromMarketplace } from '../../config/marketplaces';
import { dataWarehouse } from '../Logger';
import { getSessionId } from '../../config';
import config from '../../config';
import { decryptCustomerId } from '@amzn/amazon-id';
export class DataWarehouseLink extends ApolloLink {
    request(operation, forward) {
        var _a;
        const variables = operation.variables;
        const commId = variables.commId;
        const marketplace = variables.marketplace;
        const regionId = regionIdFromMarketplace(marketplace);
        if (!regionId) {
            console.error(`DataWarehouseLink:error marketplace: \'${marketplace}\' produced an invalid regionId`);
            return forward(operation);
        }
        ;
        dataWarehouse("DataWarehouseLink:logRequestToDataWarehouse", {
            traceId: (_a = trace.getSpanContext(context.active())) === null || _a === void 0 ? void 0 : _a.traceId,
            sessionId: getSessionId().toString(),
            commId: decryptCustomerId(commId),
            regionId,
            eventUuid: uuid(),
        }, {
            provider: "capwebsite",
            subject: "requests",
            dataset: config.REALM,
            unitPeriod: "hour",
            schema: {
                version: {
                    major: 1,
                    minor: 0,
                },
                string: 'elements: [{name: "event_uuid", type:{base:string, max_length:36}},{name: "event_datetime_utc", type:{base:timestamp, timestamp_mask:"yyyy-MM-dd\'T\'HH:mm:ss"}},{name: "trace_id", type:{base:string, max_length:32}},{name: "comm_id", type:{base:decimal, max_precision:38, max_scale:0}},{name: "event_day", type:{base:timestamp, timestamp_mask:"yyyy-MM-dd"}},{name: "session_id", type:{base:string, max_length:36}},{name: "region_id", type:{base:int, min_value:-32768, max_value:32767}}],',
                elements: [
                    "$.message.body.eventUuid",
                    "$.timestampAndes",
                    "$.message.body.traceId",
                    "$.message.body.commId",
                    "$.eventDay",
                    "$.message.body.sessionId",
                    "$.message.body.regionId",
                ],
            },
        });
        return forward(operation);
    }
}
;
