import { css, cx } from 'emotion';
import { colors, typographyStyles } from '../../util/Styles';

export const cardHeaderStyles = cx(
    typographyStyles.fontSize1,
    css`
        text-transform: uppercase;
    `,
);

export const orderCountStyles = css`
    color: ${colors.grey550};
`;
