import { toICUMessages, toICUMessageDescriptors } from './puffjToICU';
import { warn } from '../util/Logger';
import arAEStrings from './translations/strings-ar-AE.puff.json';
import bnINStrings from './translations/strings-bn-IN.puff.json';
import csCZStrings from './translations/strings-cs-CZ.puff.json';
import daDKStrings from './translations/strings-da-DK.puff.json';
import deDEStrings from './translations/strings-de-DE.puff.json';
import enAEStrings from './translations/strings-en-AE.puff.json';
import enAUStrings from './translations/strings-en-AU.puff.json';
import enCAStrings from './translations/strings-en-CA.puff.json';
import enGBStrings from './translations/strings-en-GB.puff.json';
import enINStrings from './translations/strings-en-IN.puff.json';
import enSGStrings from './translations/strings-en-SG.puff.json';
import enUSStrings from './translations/strings-en-US.puff.json';
import enZAStrings from './translations/strings-en-ZA.puff.json';
import esESStrings from './translations/strings-es-ES.puff.json';
import esMXStrings from './translations/strings-es-MX.puff.json';
import esUSStrings from './translations/strings-es-US.puff.json';
import frBEStrings from './translations/strings-fr-BE.puff.json';
import frCAStrings from './translations/strings-fr-CA.puff.json';
import frFRStrings from './translations/strings-fr-FR.puff.json';
import heILStrings from './translations/strings-he-IL.puff.json';
import hiINStrings from './translations/strings-hi-IN.puff.json';
import itITStrings from './translations/strings-it-IT.puff.json';
import jaJPStrings from './translations/strings-ja-JP.puff.json';
import knINStrings from './translations/strings-kn-IN.puff.json';
import koKRStrings from './translations/strings-ko-KR.puff.json';
import mlINStrings from './translations/strings-ml-IN.puff.json';
import mrINStrings from './translations/strings-mr-IN.puff.json';
import nlBEStrings from './translations/strings-nl-BE.puff.json';
import nlNLStrings from './translations/strings-nl-NL.puff.json';
import plPLStrings from './translations/strings-pl-PL.puff.json';
import ptBRStrings from './translations/strings-pt-BR.puff.json';
import ptPTStrings from './translations/strings-pt-PT.puff.json';
import svSEStrings from './translations/strings-sv-SE.puff.json';
import taINStrings from './translations/strings-ta-IN.puff.json';
import teINStrings from './translations/strings-te-IN.puff.json';
import trTRStrings from './translations/strings-tr-TR.puff.json';
import zhCNStrings from './translations/strings-zh-CN.puff.json';
import zhTWStrings from './translations/strings-zh-TW.puff.json';
import resources from './resources/strings.puff.json';
export const messagesByLanguageCode = {
    /* eslint-disable global-require */
    'ar-AE': toICUMessages(arAEStrings),
    'bn-IN': toICUMessages(bnINStrings),
    'cs-CZ': toICUMessages(csCZStrings),
    'da-DK': toICUMessages(daDKStrings),
    'de-DE': toICUMessages(deDEStrings),
    'en-AE': toICUMessages(enAEStrings),
    'en-AU': toICUMessages(enAUStrings),
    'en-CA': toICUMessages(enCAStrings),
    'en-GB': toICUMessages(enGBStrings),
    'en-IN': toICUMessages(enINStrings),
    'en-SG': toICUMessages(enSGStrings),
    'en-US': toICUMessages(enUSStrings),
    'en-ZA': toICUMessages(enZAStrings),
    'es-ES': toICUMessages(esESStrings),
    'es-MX': toICUMessages(esMXStrings),
    'es-US': toICUMessages(esUSStrings),
    'fr-BE': toICUMessages(frBEStrings),
    'fr-CA': toICUMessages(frCAStrings),
    'fr-FR': toICUMessages(frFRStrings),
    'he-IL': toICUMessages(heILStrings),
    'hi-IN': toICUMessages(hiINStrings),
    'it-IT': toICUMessages(itITStrings),
    'ja-JP': toICUMessages(jaJPStrings),
    'kn-IN': toICUMessages(knINStrings),
    'ko-KR': toICUMessages(koKRStrings),
    'ml-IN': toICUMessages(mlINStrings),
    'mr-IN': toICUMessages(mrINStrings),
    'nl-BE': toICUMessages(nlBEStrings),
    'nl-NL': toICUMessages(nlNLStrings),
    'pl-PL': toICUMessages(plPLStrings),
    'pt-BR': toICUMessages(ptBRStrings),
    'pt-PT': toICUMessages(ptPTStrings),
    'sv-SE': toICUMessages(svSEStrings),
    'ta-IN': toICUMessages(taINStrings),
    'te-IN': toICUMessages(teINStrings),
    'tr-TR': toICUMessages(trTRStrings),
    'zh-CN': toICUMessages(zhCNStrings),
    'zh-TW': toICUMessages(zhTWStrings),
    /* eslint-enable global-require */
};
export const getMessagesByLanguageCode = (languageCode) => {
    if (!messagesByLanguageCode[languageCode]) {
        warn('intl:getMessagesByLanguageCode:unknownLanguageCode', languageCode);
        return {};
    }
    return messagesByLanguageCode[languageCode];
};
export const messages = toICUMessageDescriptors(resources);
