// https://w.amazon.com/bin/view/LocalizationPlatform/Getting_Started/Locale_Usage#What_is_an_Amazon_Locale.3F
const amazonLocaleToIetf = {
    en_US: 'en-US',
    en_GB: 'en-GB',
};
const coerceAmazonLocaleToIetf = (amazonLocale) => amazonLocale.split('_').join('-');
export const mapAmazonLocaleToIetf = (amazonLocale) => {
    if (amazonLocaleToIetf[amazonLocale]) {
        return amazonLocaleToIetf[amazonLocale];
    }
    return coerceAmazonLocaleToIetf(amazonLocale || 'en_US');
};
// Currency codes conform to ISO 4217: https://www.six-group.com/en/products-services/financial-information/data-standards.html#scrollTo=current-historical-lists
export const marketplaceToCurrencyCode = {
    CA: 'CAD',
    DE: 'EUR',
    ES_DEVO: 'EUR',
    ES_PROD: 'EUR',
    FR: 'EUR',
    IN_DEVO: 'INR',
    IN_PROD: 'INR',
    IT_DEVO: 'EUR',
    IT_PROD: 'EUR',
    MX_DEVO: 'MXN',
    MX_PROD: 'MXN',
    UK: 'GBP',
    US: 'USD',
};
