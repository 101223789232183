import React, { Fragment } from 'react';
import { messages as untypedMessages } from '../../../../intl';
import { YesNoQuestion } from '../../../YesNoQuestion';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
;
;
export const IsConcessionValid = ({ intl, inputs, next, logEvent, disabled, }) => (React.createElement(Fragment, null,
    React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.SHOULD_CUSTOMER_BE_SAFELISTED)),
    React.createElement(YesNoQuestion, { disabled: disabled, intl: intl, onChange: (selection) => {
            next({ ...inputs, isValidConcessionRequest: selection === 'YES' });
            logEvent('IsValidConcessionAnswered', selection);
        } })));
