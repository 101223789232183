import React, { Fragment } from 'react';
import { useQuery, gql } from '@apollo/client';
import { Alert, Spinner } from '@amzn/awsui-components-react';
import * as Logger from '../../../../util/Logger';
import { messages as untypedMessages } from '../../../../intl';
import RiskBadge from '../../../RiskBadge';
import { typographyStyles } from '../../../../util/Styles';
import { css } from 'emotion';
import { globalState } from '../../../../util/Session/globalState';
import { titleStyle } from '../../styles';
const messages = untypedMessages;
;
;
export const CUSTOMER_ORDER_RISK_QUERY = gql `
    query CustomerOrderRisk($customerId: String!, $marketplace: Marketplace!, $orderId: String!) {
        customer(input: {customerId: $customerId, marketplace: $marketplace}) {
            order(input: {orderId: $orderId}) {
                risk
            }
        }
    }
`;
const doNotShareRiskStyle = css `
    color: red;
    padding-left: 5px;
`;
export const FetchAndShowRisk = ({ intl, inputs, next, logEvent, }) => {
    var _a, _b;
    const { data, loading, error, refetch } = useQuery(CUSTOMER_ORDER_RISK_QUERY, {
        variables: globalState.getSignatureValidationFields(),
        fetchPolicy: 'no-cache',
        onCompleted: (data) => {
            if (data) {
                Logger.info('CAPWorkflow:FetchAndShowRisk:succeeded', { inputs, data });
                const { risk } = data.customer.order;
                logEvent("RiskFetched", risk);
                next({ ...inputs, riskLevel: risk });
            }
            ;
        },
        onError: (error) => {
            Logger.error('CAPWorkflow:FetchAndShowRisk:failed', { inputs, error: error });
        },
    });
    if (loading)
        return React.createElement(Spinner, null);
    if (error)
        return (React.createElement(Alert, { buttonText: intl.formatMessage(messages.RETRY), content: intl.formatMessage(messages.FETCH_ERROR_SOMETHING_WENT_WRONG), onButtonClick: () => refetch(), type: 'error' }));
    if ((_b = (_a = data === null || data === void 0 ? void 0 : data.customer) === null || _a === void 0 ? void 0 : _a.order) === null || _b === void 0 ? void 0 : _b.risk) {
        return React.createElement(Fragment, null,
            React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.CONCESSION_RISK_LEVEL)),
            React.createElement(RiskBadge, { riskLevel: data.customer.order.risk }),
            React.createElement("span", { className: `${typographyStyles.fontSize0} ${doNotShareRiskStyle}` }, intl.formatMessage(messages.doNotShareRisk)));
    }
    Logger.error('CAPWorkflow:FetchAndShowRisk:useQuery:IllegalState', { ...inputs });
    throw Error('useQuery return unexpected set of outputs');
};
