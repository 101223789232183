import config from '../../config';
import { createFetchWhichRetriesTimes, fetchWithUnlimitedRetries } from './fetch';
import { instrumentPromise } from './instrumentPromise';
const fetchWithThreeRetries = createFetchWhichRetriesTimes(3);
const PUBLIC_RESOURCE_AUTHORIZATION_MAGIC_HEADER = 'OPENSESAME';
const post = (fetch, url, options = {}) => fetch(`https://${config.API_HOSTNAME}${url}`, {
    ...options,
    headers: new window.Headers({
        Accept: 'application/json',
        ...options.headers,
    }),
    method: 'POST',
});
const postWithUnlimitedRetry = post.bind(null, fetchWithUnlimitedRetries);
export const fetchLoggingCredentials = instrumentPromise('fetchLoggingCredentials', () => (post(fetchWithThreeRetries, '/api/loggingCredentials', {
    headers: {
        Authorization: PUBLIC_RESOURCE_AUTHORIZATION_MAGIC_HEADER,
    },
}).then(response => response.json())));
export const fetchNonce = instrumentPromise('fetchNonce', () => (postWithUnlimitedRetry('/api/nonce', {
    headers: {
        Authorization: PUBLIC_RESOURCE_AUTHORIZATION_MAGIC_HEADER,
    },
}).then(response => response.json())));
export const fetchStsCredentials = instrumentPromise('fetchStsCredentials', (authorizationHeader) => {
    if (process.env.STS_ACCESS_KEY && process.env.STS_SECRET_KEY && process.env.STS_SESSION_TOKEN
        && process.env.STS_EXPIRATION) {
        return Promise.resolve({
            AccessKeyId: process.env.STS_ACCESS_KEY,
            SecretAccessKey: process.env.STS_SECRET_KEY,
            SessionToken: process.env.STS_SESSION_TOKEN,
            Expiration: process.env.STS_EXPIRATION,
        });
    }
    return postWithUnlimitedRetry('/api/credentials', {
        credentials: 'include',
        headers: {
            Authorization: authorizationHeader,
        },
    }).then(response => response.json());
});
