import React, { Fragment, useState } from 'react';
import { injectIntl } from 'react-intl';
import { css } from 'emotion';
import { Button, Cards } from '@amzn/awsui-components-react';
import { v4 as uuidV4 } from 'uuid';
import { getStateMachineConfig, registeredStepComponents } from './stateMachine';
import { messages as untypedMessages } from '../../intl';
import { createMachine } from 'xstate';
import { useMachine } from '@xstate/react';
import { getLogEvent, once } from './util';
import { globalState } from '../../util/Session/globalState';
const messages = untypedMessages;
const headerStyles = css `
    display: flex;
    justify-content: space-between;
`;
export const InternalWorkflow = ({ intl, logEvent, stepComponents, stateMachine, reset, }) => {
    const [current, send] = useMachine(stateMachine);
    const visibleStates = [...current.context.priorComponents.values(), current.value];
    const isDisabled = (stateName) => current.context.priorComponents.has(stateName);
    const onClickReset = () => {
        logEvent('Reset', 'clicked');
        reset();
    };
    return (React.createElement(Fragment, null,
        React.createElement("div", { className: headerStyles },
            React.createElement("h2", null, intl.formatMessage(messages.POLICY_GUIDANCE)),
            React.createElement(Button, { className: "reset", variant: "primary", onClick: onClickReset }, intl.formatMessage(messages.RESET))),
        visibleStates.map((visibleComponentName) => stepComponents[visibleComponentName])
            .map((Component) => (React.createElement(Component, { ...{
                key: Component.name,
                disabled: isDisabled(Component.name),
                inputs: current.context.componentContext,
                intl,
                logEvent: isDisabled(Component.name)
                    ? () => { }
                    : logEvent,
                next: isDisabled(Component.name)
                    ? () => { }
                    : once((output) => send({ type: 'CONTINUE', output })),
                reset,
            } })))));
};
export const ResettableWorkflow = (props) => {
    const [key, setKey] = useState(uuidV4());
    return React.createElement(InternalWorkflow, { ...{
            ...props,
            key,
            reset: () => setKey(uuidV4()),
        } });
};
const CAPWorkflow = injectIntl(({ intl, initialContext, sessionId, }) => {
    return React.createElement(Cards, { cardDefinition: {
            header: () => (React.createElement(ResettableWorkflow, { intl: intl, logEvent: getLogEvent({ ...globalState.getSignatureValidationFields(), sessionId }), stepComponents: registeredStepComponents, stateMachine: createMachine(getStateMachineConfig(initialContext)) })),
            sections: [],
        }, cardsPerRow: [{ cards: 1 }], items: [{}] });
});
export default CAPWorkflow;
