const deduplicate = (promiseFn) => {
    let runningPromise;
    return (...args) => {
        if (!runningPromise) {
            runningPromise = promiseFn(...args)
                .then((result) => {
                runningPromise = undefined;
                return result;
            }).catch((err) => {
                runningPromise = undefined;
                throw err;
            });
        }
        return runningPromise;
    };
};
export default deduplicate;
