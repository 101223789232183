import React, { Fragment, useState } from 'react';
import { Button } from '@amzn/awsui-components-react';
import { messages } from '../../../../intl';
import { disabledSelectedStyle, titleStyle } from '../../styles';
;
;
export const ShowDamagedDefectiveClarification = ({ logEvent, next, inputs, intl, disabled, }) => {
    const [selected, setSelected] = useState();
    return React.createElement(Fragment, null,
        React.createElement("h5", { className: titleStyle }, intl.formatMessage(messages.DAMAGED_DEFECTIVE_CLARIFICATION)),
        React.createElement("span", { role: "radio", "aria-checked": "false", "aria-disabled": Boolean(disabled) },
            React.createElement(Button, { className: selected && disabled ? disabledSelectedStyle : '', variant: selected ? 'primary' : 'normal', disabled: Boolean(disabled), onClick: () => {
                    setSelected(true);
                    next({ ...inputs, selectedConcessionType: 'damagedDefective' });
                    logEvent('ClarificationAccepted', 'OK');
                } }, intl.formatMessage(messages.OK))));
};
