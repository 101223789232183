export default {
    API_HOSTNAME: 'gamma.na.cap.cs.a2z.com',
    AUTHENTICATED_REDIRECT_URL: 'https://www.gamma.na.cap.cs.a2z.com/',
    AWS_REGION: 'us-east-1',
    FEDERATE_CLIENT_ID: 'CAPWebsite-Prod-NA',
    FEDERATE_AUTHORIZATION_ENDPOINT: 'https://idp.federate.amazon.com/api/oauth2/v1/authorize',
    GLOBAL_IDENTITY_CLIENT_ID: 'CAPWebsite-prod-na',
    GLOBAL_IDENTITY_LOGIN_URL: 'https://na.amzheimdall.com/login',
    KMS_KEY_ARN: 'arn:aws:kms:us-east-1:690991977045:key/fdab427a-c19b-49f9-89ec-eb146ed20d51',
    REALM: 'na',
};
