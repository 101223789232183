import React from 'react';
import { css, cx } from 'emotion';
import { injectIntl } from 'react-intl';
import { colors, sizing, typographyStyles } from '../../util/Styles';
import { messages } from '../../intl';

const headerStyles = css`
    background-color: ${colors.awsSquidInk};
    color: ${colors.white};
    padding: ${sizing.medium} ${sizing.large};
`;

const versionStyles = cx(
    typographyStyles.fontSize0,
    css`
        margin-left: ${sizing.small};
    `,
);

export const InternalHeader = ({ intl, version }) => (
    <nav className={headerStyles}>
        <span className={typographyStyles.fontSize3}>{intl.formatMessage(messages.capWebsite)}</span>
        <span className={versionStyles}>{intl.formatMessage(messages.capWebsiteVersion, { version })}</span>
    </nav>
);

export default injectIntl(InternalHeader);
