import { ApolloClient, InMemoryCache, HttpLink, from } from '@apollo/client';

import config from '../../config';
import createSig4Fetch from './createSig4Fetch';
import { KmsDecryptionLink } from './KmsDecryptionLink';
import { OTelLink } from './OTelLink';
import { DataWarehouseLink } from './DataWarehouseLink';
import { ReadOnlyLink } from './ReadOnlyLink';
import { globalState } from '../Session/globalState';

const createClient = credentials => new ApolloClient({
    cache: new InMemoryCache(),
    // Note that the order of Links matters. These Links execute serially.
    link: from([
        new ReadOnlyLink(globalState.readOnlyMode),
        new OTelLink(),
        new DataWarehouseLink(),
        new KmsDecryptionLink(),
        new HttpLink({
            uri: `https://${config.API_HOSTNAME}/api/graphql`,
            fetch: createSig4Fetch(credentials),
            // We rely on unused variables now, but should change this since it violates the GraphQL spec
            // https://github.com/apollographql/apollo-client/blob/main/CHANGELOG.md#potentially-breaking-changes
            // https://spec.graphql.org/draft/#sec-All-Variables-Used
            includeUnusedVariables: true,
        }),
    ]),
    defaultOptions: {
        watchQuery: {
            errorPolicy: 'all',
        },
        query: {
            errorPolicy: 'all',
        },
        mutate: {
            errorPolicy: 'none',
        },
    },
});

export default createClient;
