import { fetchStsCredentials, fetchLoggingCredentials } from '../Api';
import * as Federate from './federate';
import { getSessionId } from '../../config';
import * as Logger from '../Logger';
import { LOG_INGESTION_INTERVAL } from '../Logger/constants';
import once from 'lodash/once';
import { clearCredentials403Count, getAuthenticatedCredentials, getCredentials403Count, getUnauthenticatedCredentials, incrementCredentials403Count, setAuthenticatedCredentials, setUnauthenticatedCredentials, storedAuthenticatedCredentialsAreValid, storedUnauthenticatedCredentialsAreValid, } from './credentials';
import deduplicate from './deduplicate';
const fetchAndSetAuthenticatedCredentials = (authorizationHeader) => (fetchStsCredentials(authorizationHeader)
    .then(setAuthenticatedCredentials));
const fetchAndSetUnauthenticatedCredentials = () => fetchLoggingCredentials()
    .then(setUnauthenticatedCredentials);
const requestCredentials = deduplicate(() => {
    if (storedAuthenticatedCredentialsAreValid()) {
        return Promise.resolve(getAuthenticatedCredentials());
    }
    return Federate.getCredentialsAuthorizationHeader()
        .then(fetchAndSetAuthenticatedCredentials)
        .then(getAuthenticatedCredentials)
        .then((credentials) => {
        clearCredentials403Count();
        return credentials;
    })
        .catch((e) => {
        incrementCredentials403Count();
        throw e;
    });
});
// Visible for testing
export const _renewAuthentication = () => {
    const credentials403Count = getCredentials403Count();
    if (credentials403Count > 4) {
        clearCredentials403Count();
        Logger.error('Session:ensureCredentials:TooMany403Responses', { SessionID: getSessionId(), count: credentials403Count });
        setTimeout(Federate.renewAuthentication, LOG_INGESTION_INTERVAL + 1000);
    }
    else {
        Federate.renewAuthentication();
    }
};
export const renewAuthentication = once(_renewAuthentication);
export const ensureCredentials = deduplicate(() => requestCredentials()
    .catch(renewAuthentication));
const ensureUnauthenticatedCredentials = () => {
    if (storedUnauthenticatedCredentialsAreValid()) {
        return Promise.resolve(getUnauthenticatedCredentials());
    }
    return fetchAndSetUnauthenticatedCredentials()
        .then(getUnauthenticatedCredentials);
};
export const ensureLoggingCredentials = deduplicate(() => requestCredentials()
    .catch(() => ensureUnauthenticatedCredentials()
    .finally(renewAuthentication)));
