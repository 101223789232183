import React from 'react';
import ReactDOM from 'react-dom';
import * as Bowser from 'bowser';
import { IntlProvider } from 'react-intl';
import { getSessionId } from './config';
import './util/Tracing';
import App from './components/App';
import { ensureCredentials } from './util/Session';
import { getFederateCodeFromQueryString, setQueryString } from './util/Url';
import { error, info } from './util/Logger';
import { getMessagesByLanguageCode } from './intl';
import * as LogPublisher from './util/Logger/logPublisher';
import * as DataWarehouseLogPublisher from './util/Logger/dataWarehouseLogPublisher';
import * as Storage from './util/Storage';
import { initialize, globalState } from './util/Session/globalState';

import '@amzn/awsui-components-react/index.css';

info('index:version', process.env.BUILD_VERSION);

if (Storage.hasKeys(['state'])) {
    const code = getFederateCodeFromQueryString(window.location);
    setQueryString({ ...JSON.parse(Storage.get('state') || '{}'), code });
    Storage.remove('state');
}

initialize(window.location);

const bowser = Bowser.getParser(window.navigator.userAgent);
const contactID = globalState.inputs.commId; // why are we renaming this?

// @TODO - Replace sessionID with config.getSessionID()
const sessionID = getSessionId();

LogPublisher.initialize({ contactID, sessionID });
DataWarehouseLogPublisher.initialize({ contactID, sessionID });

window.addEventListener('error', (event) => {
    error('index:globalerrorhandler', {
        accountID: globalState.inputs.accountId,
        browser: bowser.getBrowserName(),
        browserVersion: bowser.getBrowserVersion(),
        errorMessage: event.error.message,
        errorStack: event.error.stack,
        os: bowser.getOSName(),
        osVersion: bowser.getOSVersion(),
        sessionID,
        userAgent: bowser.getUA(),
    });
    LogPublisher.publish();
});


ReactDOM.render(
    <IntlProvider locale={globalState.inputs.locale} messages={getMessagesByLanguageCode(globalState.inputs.locale)}>
        <App
            ensureCredentials={ensureCredentials}
            inputs={globalState.inputs}
            sessionId={sessionID}
        />
    </IntlProvider>,
    document.getElementById('root'),
);
