import { retry } from '@lifeomic/attempt';
/**
 * API Gateway's upper-bound timeout for all integrations is 29 seconds and cannot
 * be configured any higher.
 * @see https://docs.aws.amazon.com/apigateway/latest/developerguide/limits.html#api-gateway-execution-service-limits-table
 */
const TIMEOUT = 30000;
const doesStatusStartWith = (startWithValue, status) => `${status}`.startsWith(startWithValue);
const is4xxStatus = doesStatusStartWith.bind(null, '4');
const is5xxStatus = doesStatusStartWith.bind(null, '5');
export class ResponseStatusError extends Error {
    constructor(response) {
        super(response.statusText);
        this.response = response;
    }
}
const throwOnErrorStatusCode = (response) => {
    if (is4xxStatus(response.status) || is5xxStatus(response.status)) {
        throw new ResponseStatusError(response);
    }
    return response;
};
const timeOut = () => new Promise((_, reject) => setTimeout(reject.bind(null, new Error('Request timed out')), TIMEOUT));
/**
 * The fetch specification does not currently support timeouts. This function
 * extends fetch to reject the request promise on exceeding the timeout.
 *
 * @See https://github.com/whatwg/fetch/issues/20 for fetch timeout context.
 */
const fetchWithTimeout = (uri, options) => Promise.race([
    window.fetch(uri, options).then(throwOnErrorStatusCode),
    timeOut(),
]);
const handleRetryError = (error = {}, context) => {
    const { response = {} } = error;
    if (is4xxStatus(response.status)) {
        context.abort();
    }
};
export const createFetchWhichRetriesTimes = (maxAttempts) => (...args) => (retry(fetchWithTimeout.bind(null, ...args), {
    factor: 2,
    handleError: handleRetryError,
    jitter: true,
    maxAttempts,
}));
export const fetchWithUnlimitedRetries = createFetchWhichRetriesTimes(0); // 0 represents "no limit"
