import devoEUConfig from './devo.eu';
import devoNAConfig from './devo.na';
import gammaNAConfig from './gamma.na';
import gammaEUConfig from './gamma.eu';
import prodEUConfig from './prod.eu';
import prodNAConfig from './prod.na';
import testNAConfig from './test.na';
import uuid from 'uuid';
const configMap = {
    'na-devo': devoNAConfig,
    'na-test': testNAConfig,
    'na-gamma': gammaNAConfig,
    'na-prod': prodNAConfig,
    'eu-devo': devoEUConfig,
    'eu-gamma': gammaEUConfig,
    'eu-prod': prodEUConfig,
};
const getAppStage = () => process.env.APP_STAGE || 'na-prod';
export const getConfig = (stage) => {
    if (!configMap[stage]) {
        throw new Error(`Missing config for ${stage}`);
    }
    return Object.freeze(configMap[stage]);
};
const sessionID = uuid.v4();
export const getSessionId = () => (sessionID);
export default getConfig(getAppStage());
