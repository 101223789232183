// @see https://polaris.a2z.com/fundamentals/foundation/colors/
export const colors = {
    awsSquidInk: '#232f3e',
    blue600: '#0073bb',
    green500: '#6aaf35',
    grey150: '#f2f3f3',
    grey300: '#d5dbdb',
    grey550: '#687078',
    grey900: '#16191f',
    white: '#ffffff',
};

export const sizing = {
    xsmall: '0.3em',
    small: '0.5em',
    medium: '1em',
    large: '1.5em',
};

// @see https://polaris.a2z.com/fundamentals/foundation/typography/
export const typographyStyles = {
    fontSize0: 'awsui-util-font-size-0',
    fontSize1: 'awsui-util-font-size-1',
    fontSize2: 'awsui-util-font-size-2',
    fontSize3: 'awsui-util-font-size-3',
    fontSize4: 'awsui-util-font-size-4',
};
