import { LogPublisher } from './logPublisher';
import { dataWarehouseLogQueue } from './dataWarehouseLogQueue';

const DATA_WAREHOUSE_LOG_GROUP_NAME = 'BrowserClientDataWarehouseLogGroup';

let logPublisher;

export const initialize = ({ contactID, sessionID }) => {
    logPublisher = new LogPublisher(contactID, sessionID, DATA_WAREHOUSE_LOG_GROUP_NAME, dataWarehouseLogQueue);
    return logPublisher.ingester;
};

export const publish = () => logPublisher.publish();

export const stopIngestion = () => logPublisher.stopIngestion();
