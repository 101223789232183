export default {
    API_HOSTNAME: 'na.cap.cs.a2z.com',
    AUTHENTICATED_REDIRECT_URL: 'https://www.na.cap.cs.a2z.com/',
    AWS_REGION: 'us-east-1',
    FEDERATE_CLIENT_ID: 'CAPWebsite-Prod-NA',
    FEDERATE_AUTHORIZATION_ENDPOINT: 'https://idp.federate.amazon.com/api/oauth2/v1/authorize',
    GLOBAL_IDENTITY_CLIENT_ID: 'CAPWebsite-prod-na',
    GLOBAL_IDENTITY_LOGIN_URL: 'https://na.amzheimdall.com/login',
    KMS_KEY_ARN: 'arn:aws:kms:us-east-1:866426823908:key/dd792bc1-e72f-41f3-8386-e2c53726efb2',
    REALM: 'na',
};
