import { ApolloLink, Observable } from '@apollo/client';
import { OperationTypeNode } from 'graphql';
export class ReadOnlyLink extends ApolloLink {
    constructor(readOnlyMode) {
        super();
        this.readOnlyMode = readOnlyMode;
    }
    operationIncludesMutation(operation) {
        return operation.query.definitions
            .filter((definition) => {
            return definition.operation && definition.operation === OperationTypeNode.MUTATION;
        })
            .length > 0;
    }
    request(operation, forward) {
        if (this.readOnlyMode && this.operationIncludesMutation(operation)) {
            return Observable.of({ data: undefined, errors: [] });
        }
        return forward(operation);
    }
}
;
