export default {
    API_HOSTNAME: 'gamma.eu.cap.cs.a2z.com',
    AUTHENTICATED_REDIRECT_URL: 'https://www.gamma.eu.cap.cs.a2z.com/',
    AWS_REGION: 'eu-west-1',
    FEDERATE_CLIENT_ID: 'CAPWebsite-Prod-EU',
    FEDERATE_AUTHORIZATION_ENDPOINT: 'https://idp.federate.amazon.com/api/oauth2/v1/authorize',
    GLOBAL_IDENTITY_CLIENT_ID: 'CAPWebsite-prod-eu',
    GLOBAL_IDENTITY_LOGIN_URL: 'https://eu.amzheimdall.com/login',
    KMS_KEY_ARN: 'arn:aws:kms:eu-west-1:926345353149:key/07063d6d-b900-4f2d-a3b8-de9fd1317499',
    REALM: 'eu',
};
